import { CONTRACT_CONDITION_TYPE } from '@keyliving/shared-types';

import { useSelector } from '../redux/hooks';
import { selectors as suiteSelectors } from '../redux/modules/suite';

/**
 * Based on the asset contracts, is the user on the Key Firm plan
 *
 * @returns {Boolean} Return true or false
 */
export default function useIsFirmPlan() {
    const contracts = useSelector(suiteSelectors.selectSuiteContracts);

    const foundContract = contracts.find(
        (contract) => contract.type === CONTRACT_CONDITION_TYPE.EQUITY_GROWTH_ANNUAL_EFFECTIVE
    );

    return !!foundContract;
}
