import { forwardRef } from 'react';

import BaseInput, { BaseInputProps } from '../BaseInput';

export type TextInputProps = BaseInputProps;

/**
 * NOTE: The type is purposefully "text" here vs the allowed HTMLInputTypeAttribute
 * types. If we pass type="email" the browsers validation takes over if the input
 * is incorrect. We use Zod for validation and display error messages accordinly.
 * The browsers validation message adds confusion and obscures the error
 * message under the input. If your looking for keyboard hinting, see if
 * the inputMode prop works for you.
 */

const TextInput = forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => {
    return <BaseInput {...props} ref={ref} type="text" />;
});

export default TextInput;
