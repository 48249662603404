import classNames from 'classnames/bind';

import classes from './Skeleton.module.scss';

const cx = classNames.bind(classes);

export interface BoneProps {
    /** Add additional css classes */
    className?: string;
    /** The height CSS property passed to the style prop  */
    height?: string | number;
    /** The width CSS property passed to the style prop  */
    width?: string | number;
}

export default function Bone({ className, height, width }: BoneProps) {
    return (
        <div
            className={cx('bone', className)}
            data-testid="skeleton"
            style={{
                width,
                height,
            }}
        />
    );
}
