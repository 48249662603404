import { useRef } from 'react';

import { useGooglePlace } from '../../../hooks/useGooglePlace';
import { Button } from '../../UI/Button';
import { TextInput } from '../../UI/TextInput';
import styles from '../FundingAccountModal.module.scss';
import { BasicInfoProps } from './types';

export function BasicInfo({
    city,
    country,
    firstName,
    lastName,
    postalCode,
    province,
    setCity,
    setCountry,
    setFirstName,
    setIsSubmitting,
    setLastName,
    setPostalCode,
    setProvince,
    setStreet,
    street,
}: BasicInfoProps) {
    const googlePlaceRef = useRef<HTMLInputElement>(null);
    useGooglePlace(googlePlaceRef, { setStreet, setCity, setProvince, setCountry, setPostalCode });

    const handleChange = (event): void => {
        switch (event.target.name) {
            case 'Street':
                setStreet(event.target.value);
                break;

            case 'City':
                setCity(event.target.value);
                break;

            case 'Province':
                setProvince(event.target.value);
                break;

            case 'Country':
                setCountry(event.target.value);
                break;

            case 'Postal Code':
                setPostalCode(event.target.value);
                break;

            default:
                return;
        }
    };

    return (
        <>
            <TextInput
                className={styles.sectionTextInput}
                label="First Name"
                setValue={setFirstName}
                value={firstName}
            />
            <TextInput
                className={styles.sectionTextInput}
                label="Last Name"
                setValue={setLastName}
                value={lastName}
            />
            <TextInput
                className={styles.sectionTextInput}
                label="Street Address"
                name="Street"
                onChange={handleChange}
                placeholder=""
                ref={googlePlaceRef}
                value={street}
            />
            <TextInput
                className={styles.sectionTextInput}
                label="City"
                name="City"
                onChange={handleChange}
                value={city}
            />
            <TextInput
                className={styles.sectionTextInput}
                label="Province/Territory"
                name="Province"
                onChange={handleChange}
                value={province}
            />
            <TextInput
                className={styles.sectionTextInput}
                label="Country"
                name="Country"
                onChange={handleChange}
                value={country}
            />
            <TextInput
                className={styles.sectionTextInput}
                label="Postal Code"
                name="Postal Code"
                onChange={handleChange}
                value={postalCode}
            />
            <Button
                className={styles.continueChipButton}
                colour="black"
                disabled={
                    !firstName ||
                    !lastName ||
                    !street ||
                    !city ||
                    !province ||
                    !country ||
                    !postalCode
                }
                label="Continue"
                onClick={() => setIsSubmitting(true)}
            />
        </>
    );
}
