import { useBoundingclientrect } from '@keyliving/component-lib';
import { toMoney } from '@keyliving/utils';
import { format } from 'date-fns';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine } from 'victory';

import colors from '../colors.module.scss';
import { formatLabel, getLowerRange, getUpperRange } from '../lib/helpers';
import type { EquityChartData } from '../types';
import styles from './EquityPerformanceChart.module.scss';

interface EquityPerformanceChartProps {
    data: EquityChartData[];
    xAxisMonths: Date[];
}

export function EquityPerformanceChart({
    data,
    xAxisMonths,
}: EquityPerformanceChartProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const [canMount, setCanMount] = useState<boolean>(false);
    const { width: containerWidth } = useBoundingclientrect(ref);

    const yLowerRange = getLowerRange(data, {
        offset: 1_000,
        accessor({ purchased, value }) {
            // We want the lowest value of the two
            return Math.min(purchased, value);
        },
    });

    const yUpperRange = getUpperRange(data, {
        offset: 1_000,
        accessor({ purchased, value }) {
            // We want the larget value of the two
            return Math.max(purchased, value);
        },
    });

    /**
     * Show the upper and lower values then fill in the middle evenly
     */
    const yTickValues = useMemo(() => {
        /**
         * There is a chance that we don't have any data the upper/lower range
         * is infinity. 0 and $80,000 are arbitrary numbers.
         */
        const yLower = yLowerRange === Number.POSITIVE_INFINITY ? 0 : yLowerRange;
        const yUpper = yUpperRange === Number.NEGATIVE_INFINITY ? 80_000 : yUpperRange;

        const difference = yUpper - yLower;
        const increment = Math.round(difference / 5);

        const ticks = [yLower];

        for (let index = 0; index < 4; index++) {
            const prev = ticks[index];
            const next = prev + increment;
            ticks.push(next);
        }

        return [...ticks, yUpper];
    }, [yLowerRange, yUpperRange]);

    useLayoutEffect(() => {
        /**
         * Need to wait until finished rendering so we can
         * properly calculate the containers width.
         */
        setCanMount(true);
    }, []);

    return (
        <div className={styles.wrapper} ref={ref}>
            {canMount ? (
                <>
                    <div className={styles.legend}>
                        <div className={styles.legend__label}>
                            <div
                                className={styles['legend__label-color']}
                                style={{
                                    backgroundColor: colors.equityPurchased,
                                }}
                            />
                            <div>Equity Purchased</div>
                        </div>
                        <div className={styles.legend__label}>
                            <div
                                className={styles['legend__label-color']}
                                style={{
                                    backgroundColor: colors.equityValue,
                                }}
                            />
                            <div>Equity Value</div>
                        </div>
                    </div>
                    <VictoryChart
                        // containerComponent={
                        //     <VictoryVoronoiContainer
                        //         labelComponent={<VictoryTooltip flyoutComponent={<FlyOut />} />}
                        //         labels={() => ' '}
                        //         voronoiDimension="x"
                        //     />
                        // }

                        // TODO: This might not be needed
                        // domain={{
                        //     y: [yTickValues[0], yTickValues[yTickValues.length - 1]],
                        // }}
                        height={containerWidth * 0.65}
                        padding={{
                            left: 85, // 2rem dashboard padding + padding for numbers
                            bottom: 100,
                            right: 40,
                            top: 10,
                        }}
                        scale={{ x: 'time', y: 'linear' }}
                        width={containerWidth}
                    >
                        <VictoryAxis
                            dependentAxis
                            style={{
                                grid: {
                                    stroke: colors.grey200,
                                    strokeWidth: 1,
                                },
                                axis: { stroke: 'none' },
                                tickLabels: {
                                    fontSize: 14,
                                    fill: colors.grey500,
                                },
                            }}
                            tickFormat={(y: number) =>
                                toMoney(y, 0, {
                                    compactDisplay: 'short',
                                    notation: 'compact',
                                    maximumFractionDigits: 1,
                                    minimumFractionDigits: 1,
                                })
                            }
                            tickValues={yTickValues}
                        />
                        <VictoryAxis
                            domainPadding={[0, 0]}
                            fixLabelOverlap
                            scale="time"
                            style={{
                                axis: { stroke: '#E5E5E5' },
                                tickLabels: {
                                    fontSize: 14,
                                    fill: colors.grey500,
                                },
                            }}
                            tickFormat={(x: Date) => {
                                if (data.length <= 12) {
                                    return format(x, 'MMM');
                                }

                                return formatLabel.formatXAxis(format(x, 'MMM yyyy'));
                            }}
                            tickLabelComponent={<VictoryLabel dx={0} dy={10} />}
                            tickValues={xAxisMonths}
                        />

                        <VictoryLine
                            data={data}
                            style={{
                                data: {
                                    stroke: colors.equityPurchased,
                                    strokeWidth: 3,
                                    strokeLinecap: 'round',
                                },
                            }}
                            x="date"
                            y="purchased"
                        />
                        <VictoryLine
                            data={data}
                            style={{
                                data: {
                                    stroke: colors.equityValue,
                                    strokeWidth: 3,
                                    strokeLinecap: 'round',
                                },
                            }}
                            x="date"
                            y="value"
                        />
                    </VictoryChart>
                </>
            ) : null}
        </div>
    );
}
