import { useEffect } from 'react';

declare var google: any;
let autocomplete;

export const useGooglePlace = (ref, setFields): void => {
    const { setCity, setCountry, setPostalCode, setProvince, setStreet } = setFields;

    useEffect(() => {
        autocomplete = new google.maps.places.Autocomplete(ref.current);
        autocomplete.setFields(['address_components']);

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            const addressComponents = place.address_components;

            const streetNumber = addressComponents.find(
                ({ types }) => types[0] === 'street_number'
            ).long_name;
            const street = addressComponents.find(({ types }) => types[0] === 'route').long_name;
            const address = `${streetNumber} ${street}`;
            setStreet(address);

            const city = addressComponents.find(({ types }) => types[0] === 'locality').long_name;
            setCity(city);

            const province = addressComponents.find(
                ({ types }) => types[0] === 'administrative_area_level_1'
            ).long_name;
            setProvince(province);

            const country = addressComponents.find(({ types }) => types[0] === 'country').long_name;
            setCountry(country);

            const postalCode = addressComponents.find(
                ({ types }) => types[0] === 'postal_code'
            ).long_name;
            setPostalCode(postalCode);
        });
    }, [ref, setCity, setCountry, setPostalCode, setProvince, setStreet]);
};
