import { ReactNode } from 'react';

import classes from './DashboardTile.module.scss';

interface ValueProps {
    className: string;
}

interface DashboardTileProps {
    content: string | ((props: ValueProps) => ReactNode);
    label: string;
}

export default function DashboardTile({ content, label }: DashboardTileProps) {
    const tileContent =
        typeof content === 'string' ? (
            <div className={classes.tile__content}>{content}</div>
        ) : (
            content({ className: classes.tile__content })
        );

    return (
        <div className={classes.tile}>
            <div>{label}</div>
            {tileContent}
        </div>
    );
}
