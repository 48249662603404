import { ReactNode } from 'react';

import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
    children: ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {
    return (
        <div className={styles.container}>
            <div className={styles.rightCol}>
                <img
                    alt="Login"
                    src={process.env.REACT_APP_GCP_BASE_URL + 'resident-portal/signin.jpg'}
                />
            </div>
            <div className={styles.leftCol}>{children}</div>
        </div>
    );
}
