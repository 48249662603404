import { Claims } from '@keyliving/shared-types';

import { fetch } from '../../../lib/fetch';
import { AppThunk } from '../../store';
import { KEY_EMAIL_NAME, SUPPORT_EMAIL, UPDATE_PROFILE_INFO_ERROR_MESSAGE } from './constants';
import { type AuthAction, type AuthState, type UserType, AuthActionTypes } from './types';

const onRequestLogout = (): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_LOGOUT,
});

const onRequestSendEmail = (): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_SEND_EMAIL,
});

const onRequestSendEmailSuccess = (): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_SEND_EMAIL_SUCCESS,
});

const onRequestSendEmailFail = (payload: string): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_SEND_EMAIL_FAIL,
    payload,
});

const onRequestPasswordReset = (): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_PASSWORD_RESET,
});

const onRequestPasswordResetSuccess = (): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_PASSWORD_RESET_SUCCESS,
});

const onRequestPasswordResetFail = (payload: string): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_PASSWORD_RESET_FAIL,
    payload,
});

const onRequestNewPassword = (): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_NEW_PASSWORD,
});

const onRequestNewPasswordSuccess = (): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_NEW_PASSWORD_SUCCESS,
});

const onRequestNewPasswordFail = (payload: string): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_NEW_PASSWORD_FAIL,
    payload,
});

const onRequestUpdateUser = (): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_UPDATE_USER,
});

const onRequestUpdateUserSuccess = (payload: UserType): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_UPDATE_USER_SUCCESS,
    payload,
});

const onRequestUpdateUserFail = (payload: string): AuthAction => ({
    type: AuthActionTypes.ON_REQUEST_UPDATE_USER_FAIL,
    payload,
});

const setAuthState = (payload: AuthState): AuthAction => ({
    type: AuthActionTypes.ON_SET_AUTH_STATE,
    payload,
});

const saveAuthData =
    (
        token: string | null,
        user: UserType | null,
        claims: Claims | null
    ): AppThunk<Promise<boolean>> =>
    (dispatch) => {
        dispatch(setAuthState({ user, token, claims }));

        return Promise.resolve(true);
    };

const requestSendEmail = (subject: string, message: string): Function => {
    return (dispatch, getState): Promise<boolean> => {
        const {
            auth: {
                user: { email, name, phone_number },
            },
        } = getState();

        return fetch(`${process.env.REACT_APP_API_SERVICE_ENDPOINT}/email/send`, {
            method: 'POST',
            body: JSON.stringify({
                subject: 'General Inquiry From Owner Resident',
                from: {
                    email: SUPPORT_EMAIL,
                    name: KEY_EMAIL_NAME,
                },
                to: {
                    email: SUPPORT_EMAIL,
                },
                html: `
                    <p>Name: ${name}</p>
                    <p>Email: ${email}</p>
                    <p>Phone Number: ${phone_number}</p>
                    <p>Subject: ${subject}</p>
                    <p>Message: ${message}</p>
                `,
            }),
        })
            .then(() => {
                dispatch(onRequestSendEmailSuccess());
                return true;
            })
            .catch(() => {
                dispatch(
                    onRequestSendEmailFail(
                        `We were unable to send your message. Please contact us directly at ${SUPPORT_EMAIL}`
                    )
                );
                return false;
            });
    };
};

const requestPasswordReset = (email: string): Function => {
    return (dispatch): Promise<boolean> => {
        dispatch(onRequestPasswordReset());
        return fetch(
            `${process.env.REACT_APP_IDENTITY_SERVICE_ENDPOINT}/users/request-password-reset`,
            {
                method: 'PUT',
                body: JSON.stringify({ email: email.toLowerCase().trim() }),
                raw: true,
            }
        )
            .then(() => {
                dispatch(onRequestPasswordResetSuccess());
                return true;
            })
            .catch(() => {
                dispatch(
                    onRequestPasswordResetFail(
                        `Could not reset password. Please contact ${SUPPORT_EMAIL} for help.`
                    )
                );
                return false;
            });
    };
};

const requestNewPassword = (password: string, token: string): Function => {
    return (dispatch): Promise<boolean> => {
        dispatch(onRequestNewPassword());
        return fetch(`${process.env.REACT_APP_IDENTITY_SERVICE_ENDPOINT}/users/password-reset`, {
            method: 'PUT',
            body: JSON.stringify({ password, token }),
            raw: true,
        })
            .then(() => {
                dispatch(onRequestNewPasswordSuccess());
                return true;
            })
            .catch(() => {
                dispatch(
                    onRequestNewPasswordFail(
                        `Could not reset password. Please contact ${SUPPORT_EMAIL} for help.`
                    )
                );
                return false;
            });
    };
};

const requestUpdateUser = (email: string, phoneNumber: string): Function => {
    return (dispatch, getState): Promise<boolean> => {
        const {
            auth: { user },
        } = getState();
        dispatch(onRequestUpdateUser());
        return fetch(`${process.env.REACT_APP_API_SERVICE_ENDPOINT}/users/update`, {
            method: 'PUT',
            body: JSON.stringify({
                id: user.id,
                email,
                phoneNumber,
            }),
        })
            .then(({ data }) => {
                const { email, phone_number } = data;
                dispatch(onRequestUpdateUserSuccess({ ...user, email, phone_number }));

                return true;
            })
            .catch(() => {
                dispatch(onRequestUpdateUserFail(UPDATE_PROFILE_INFO_ERROR_MESSAGE));
                return false;
            });
    };
};

const requestCurrentUser = (): AppThunk<Promise<UserType>> => {
    return async (dispatch): Promise<UserType> => {
        try {
            const { data } = await fetch(
                `${process.env.REACT_APP_IDENTITY_SERVICE_ENDPOINT}/users/me`,
                {
                    method: 'GET',
                }
            );

            const { user } = data;

            dispatch(onRequestUpdateUserSuccess(user));

            return Promise.resolve(user);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const actions = {
    saveAuthData,
    onRequestLogout,
    requestSendEmail,
    onRequestSendEmail,
    onRequestSendEmailSuccess,
    onRequestSendEmailFail,
    requestPasswordReset,
    onRequestPasswordReset,
    onRequestPasswordResetSuccess,
    onRequestPasswordResetFail,
    requestNewPassword,
    onRequestNewPassword,
    onRequestNewPasswordSuccess,
    onRequestNewPasswordFail,
    requestUpdateUser,
    onRequestUpdateUser,
    onRequestUpdateUserSuccess,
    onRequestUpdateUserFail,
    requestCurrentUser,
};
