const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export const toMoney = (input: number): string => formatter.format(input);

export const numberFromMoney = (input: string): number => {
    return Number(input.replace(/[^0-9.-]+/g, ''));
};

export const toPercent = (input: number): string => `${(input * 100).toFixed(2)}%`;
