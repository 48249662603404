import { fetch } from '../../../lib/fetch';
import { AppThunk } from '../../store';
import { FundingActionTypes } from './types';

const onRequestFundingAccount = () => ({
    type: FundingActionTypes.ON_REQUEST_FUNDING_ACCOUNT,
});

const onRequestFundingAccountSuccess = (payload) => ({
    type: FundingActionTypes.ON_REQUEST_FUNDING_ACCOUNT_SUCCESS,
    payload,
});

const onRequestFundingAccountFail = (payload: string) => ({
    type: FundingActionTypes.ON_REQUEST_FUNDING_ACCOUNT_FAIL,
    payload,
});

const onRequestUpdateFundingAccount = () => ({
    type: FundingActionTypes.ON_REQUEST_UPDATE_FUNDING_ACCOUNT,
});

const onRequestUpdateFundingAccountSuccess = (payload: string) => ({
    type: FundingActionTypes.ON_REQUEST_UPDATE_FUNDING_ACCOUNT_SUCCESS,
    payload,
});

const onRequestUpdateFundingAccountFail = (payload: string) => ({
    type: FundingActionTypes.ON_REQUEST_UPDATE_FUNDING_ACCOUNT_FAIL,
    payload,
});

const requestFundingAccount = (): AppThunk<Promise<void>> => {
    return async (dispatch, getState) => {
        dispatch(onRequestFundingAccount());

        const {
            auth: {
                user: { id: userId },
            },
        } = getState();

        const params = new URLSearchParams({ userId, censor: 'true' });

        return fetch(
            `${process.env.REACT_APP_API_SERVICE_ENDPOINT}/funding-account?${params.toString()}`,
            { method: 'GET' }
        )
            .then(({ data }) => {
                dispatch(onRequestFundingAccountSuccess(data));
            })
            .catch(() => {
                dispatch(onRequestFundingAccountFail('Cannot retrieve funding account'));
            });
    };
};

const updateFundingAccount = (account_info): Function => {
    return (dispatch, getState): Promise<any> => {
        dispatch(onRequestUpdateFundingAccount());

        const {
            auth: {
                user: { id: userId },
            },
        } = getState();

        return fetch(`${process.env.REACT_APP_API_SERVICE_ENDPOINT}/funding-account/update`, {
            method: 'PUT',
            body: JSON.stringify({ userId, accountInfo: account_info }),
        })
            .then(({ data }) => {
                dispatch(onRequestUpdateFundingAccountSuccess(data));
            })
            .catch(() => {
                dispatch(onRequestUpdateFundingAccountFail('Cannot update funding account'));
            });
    };
};

export const actions = {
    requestFundingAccount,
    updateFundingAccount,
    onRequestFundingAccount,
    onRequestFundingAccountSuccess,
    onRequestFundingAccountFail,
    onRequestUpdateFundingAccount,
    onRequestUpdateFundingAccountSuccess,
    onRequestUpdateFundingAccountFail,
};
