export enum EquityPurchaseTypes {
    ON_REQUEST_EQUITY_FAIL = 'ON_REQUEST_EQUITY_FAIL',
    ON_REQUEST_EQUITY_PURCHASE = 'ON_REQUEST_EQUITY_PURCHASE',
    ON_REQUEST_EQUITY_PURCHASE_SUCCESS = 'ON_REQUEST_EQUITY_PURCHASE_SUCCESS',
}

export enum EQUITY_PURCHASE_ORDER_STATUS {
    APPROVED = 'APPROVED',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED',
    SUBMITTED = 'SUBMITTED',
}

export type EquityPurchaseState = {
    amount: number;
    created_at: Date;
    error?: string | undefined;
    status: EQUITY_PURCHASE_ORDER_STATUS;
    updated_at: Date;
    user_id: string;
};
