import { IconProps } from './types';

export default function ArrowForwardIcon({ height = 8, width = 18, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 18.5 8.5"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="m14.34 7.75 3.41-3.5-3.41-3.5M.75 4.25h17"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
