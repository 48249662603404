import { useGlobalAuth } from '@keyliving/component-lib';
import { Claims } from '@keyliving/shared-types';
import { isTokenExpired } from '@keyliving/utils';
import { useCallback, useEffect, useState } from 'react';

import { verifyAuthToken } from '../lib/verifyAuthToken';
import { useThunkDispatch } from '../redux/hooks';
import { actions as authActions } from '../redux/modules/auth';
import { actions as financeActions } from '../redux/modules/finance';
import { actions as fundingActions } from '../redux/modules/funding';
import { actions as invoiceActions } from '../redux/modules/invoice';
import { actions as suiteActions } from '../redux/modules/suite';

export const useHydration = () => {
    const { token: authToken } = useGlobalAuth();
    const thunkDispatch = useThunkDispatch();
    const [canMount, setCanMount] = useState<boolean>(false);

    const verifyToken = useCallback(async (token: string): Promise<Claims> => {
        try {
            // Verify the token...
            const claims = await verifyAuthToken(token);

            // ...token expiry should be handled by the api, but to be safe...
            const isExpired = isTokenExpired(claims.exp);

            if (isExpired) {
                throw new Error('Token expired');
            }

            return Promise.resolve(claims);
        } catch (error) {
            return Promise.reject('Token has expired');
        }
    }, []);

    const hydrateAppState = useCallback(
        async (authToken: string) => {
            const impersonationToken = sessionStorage.getItem('impersonationToken');

            try {
                const tokensToVerify = [verifyToken(authToken)];

                if (impersonationToken) {
                    tokensToVerify.push(verifyToken(impersonationToken));
                }

                const [authUserClaims, impersonationClaims] = await Promise.all(tokensToVerify);

                let claims = authUserClaims;
                let token = authToken;

                if (impersonationClaims && impersonationToken) {
                    const adminId = authUserClaims.id;
                    const impersonatorId = impersonationClaims.act?.sub;

                    /**
                     * We've tried to use a token that we aren't authenticated to use
                     */
                    if (!impersonatorId || impersonatorId !== adminId) {
                        /**
                         * Maybe a low chance this is helpful but might alert us if someone
                         * is trying to do something nefarious.
                         *
                         * TODO: Add Sentry
                         */
                        // captureException(
                        //     new Error(`[Impersonation]: Unauthorized to impersonate user`),
                        //     {
                        //         tags: {
                        //             page: 'Impersonation',
                        //         },
                        //         extra: {
                        //             // User who is logged in via auth cookie
                        //             adminUserId: adminId,
                        //             // The "actor" on the impersonation token
                        //             impersonatorId,
                        //             // User the impersonation token is for
                        //             impersonatedUserId: impersonationClaims.id,
                        //         },
                        //     }
                        // );

                        throw new Error(
                            'The actor user.id on the impersonation token does not match the user.id of the person who is trying to impersonate them'
                        );
                    }

                    /**
                     * If we are impersonating a user, we set our state to the impersonated users
                     * token and claims.
                     */
                    claims = impersonationClaims;
                    token = impersonationToken;
                }

                await thunkDispatch(authActions.saveAuthData(token, null, claims));

                // Get the user - save user details
                await thunkDispatch(authActions.requestCurrentUser());

                // API calls that don't depend on previous results other than user.id
                await Promise.allSettled([
                    thunkDispatch(suiteActions.requestSuiteData()), // auth.user.id
                    thunkDispatch(invoiceActions.requestPaymentHistoryData()), // auth.user.id
                    thunkDispatch(fundingActions.requestFundingAccount()), // auth.user.id
                ]);

                await Promise.allSettled([
                    // requires previously fetched suite data
                    thunkDispatch(suiteActions.requestSuiteValues()), // suite.id
                    thunkDispatch(suiteActions.requestSuiteImage()), // suite.image_folder_id

                    // requires previously fetched suite data
                    thunkDispatch(financeActions.requestMonthlySnapshots()), //  auth.user.id, suite.id

                    // requires prevously fetched payment history
                    thunkDispatch(invoiceActions.requestInvoiceData()), // invoice.id
                ]);

                setCanMount(true);
            } catch (error) {
                setCanMount(true);
            }
        },
        [verifyToken, thunkDispatch]
    );

    useEffect((): void => {
        if (!authToken) {
            setCanMount(true);
            return;
        }

        hydrateAppState(authToken);

        // Only once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        canMount,
    };
};
