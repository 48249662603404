import { toMoney, toPercent } from '@keyliving/utils';

import { SectionItem } from '../../../../components/Section/SectionItem';
import { NonNullableRecord } from '../../../../types';
import { resolvePercentageChange } from '../../../home/DashboardTiles/CurrentEquityValueDashboardTile';

interface ChangeSinceInceptionProps {
    currentEquityValue: number | null;
    currentTotalCofinancingAllocated: number | null;
    totalEquityPurchased: number | null;
}

function ChangeSinceInception({
    currentEquityValue,
    currentTotalCofinancingAllocated,
    totalEquityPurchased,
}: NonNullableRecord<ChangeSinceInceptionProps>) {
    const currentRealEquityValue = currentEquityValue - currentTotalCofinancingAllocated;
    const equityPercentageChange = resolvePercentageChange(
        totalEquityPurchased,
        currentRealEquityValue
    );
    const returnOnEquity = currentRealEquityValue - totalEquityPurchased;

    return (
        <SectionItem
            label="Return on equity"
            percent={toPercent(equityPercentageChange, {
                stripCommas: false,
                maximumFractionDigits: 1,
            })}
            value={toMoney(returnOnEquity, 0, {
                maximumFractionDigits: 0,
            })}
            valueSize="medium"
        />
    );
}

export default function WrappedChangeSinceInception({
    currentEquityValue,
    currentTotalCofinancingAllocated,
    totalEquityPurchased,
}: ChangeSinceInceptionProps) {
    // If any values are null
    if (
        currentEquityValue === null ||
        currentTotalCofinancingAllocated === null ||
        totalEquityPurchased === null
    ) {
        return null;
    }

    return (
        <ChangeSinceInception
            currentEquityValue={currentEquityValue}
            currentTotalCofinancingAllocated={currentTotalCofinancingAllocated}
            totalEquityPurchased={totalEquityPurchased}
        />
    );
}
