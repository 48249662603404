import { AnyAction, Reducer } from 'redux';

import {
    type EquityPurchaseState,
    EQUITY_PURCHASE_ORDER_STATUS,
    EquityPurchaseTypes,
} from './types';

export const EQUITY_PURCHASE_INITIAL_STATE: EquityPurchaseState = {
    user_id: '',
    amount: 0,
    status: EQUITY_PURCHASE_ORDER_STATUS.SUBMITTED,
    created_at: new Date(),
    updated_at: new Date(),
};

export const equityPurchaseReducer: Reducer = (
    state: EquityPurchaseState = EQUITY_PURCHASE_INITIAL_STATE,
    action: AnyAction
) => {
    switch (action.type) {
        case EquityPurchaseTypes.ON_REQUEST_EQUITY_PURCHASE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };

        case EquityPurchaseTypes.ON_REQUEST_EQUITY_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
