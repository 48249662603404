import { useNavigate } from 'react-router-dom';

import * as Icons from '../../../../assets/icons';
import { Section } from '../../../../components/Section';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import { Button } from '../../../../components/UI/Button';
import styles from './Submitted.module.scss';

export function Submitted(): JSX.Element {
    const navigate = useNavigate();

    const handleReturnToDashboard = () => {
        navigate('/home');
    };

    return (
        <Section>
            <SectionHeader header="EQUITY PURCHASE ORDER SUBMITTED" />
            <div className={styles.subSection}>
                <div className={styles.icon}>
                    <img alt="Celebrate Icon" src={Icons.Celebrate} />
                </div>
                <div>
                    <p>
                        Thank you for submitting your equity purchase. Your equity purchase will be
                        reflected on your monthly invoice on the 25th of the month.
                    </p>
                    <p>
                        Changes to your Key Home Equity will be reflected on the dashboard once the
                        monthly transactions has been successfully debited.
                    </p>
                    <Button
                        className={styles.button}
                        colour="white"
                        label="Return to Dashboard"
                        onClick={handleReturnToDashboard}
                    />
                </div>
            </div>
        </Section>
    );
}
