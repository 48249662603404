import type { CapTableEntry, FinanceState } from './types';

const selectFinancialSummary = (state): FinanceState => state.finance;
const selectFinancialCapTableCurrent = (state): CapTableEntry | null =>
    state.finance.capTableCurrent;
const selectFinancialCapTableHistory = (state): CapTableEntry[] => state.finance.capTableHistory;

export const selectors = {
    selectFinancialSummary,
    selectFinancialCapTableCurrent,
    selectFinancialCapTableHistory,
};
