export enum SHOW_OPTIONS {
    DATE_RANGE = 'Date Range',
    RECENT_ACTIVITIES = 'Recent Activities',
}

export enum FILTER_BY_TYPE_OPTIONS {
    ADJUSTMENTS = 'Adjustments',
    ALL_TRANSACTIONS = 'All Transactions',
    AUTO_EQUITY_PURCHASE = 'Auto Equity Purchase',
    CO_FINANCING_ALLOTMENT = 'Co-Financing Allotment',
    EQUITY_BOOST = 'Equity Boost',
    EQUITY_CHANGE = 'Equity Change',
    EQUITY_PURCHASE = 'Equity Purchase',
    INITIAL_INVESTMENT = 'Initial Investment',
    OWNERSHIP_SAVINGS_REINVESTMENT = 'Ownership Savings Reinvestment',
}

export const TRANSACTION_LIST_OFFSET = 5;
