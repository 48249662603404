import { Claims } from '@keyliving/shared-types';

export enum AuthActionTypes {
    ON_REQUEST_LOGOUT = 'ON_REQUEST_LOGOUT',
    ON_REQUEST_NEW_PASSWORD = 'ON_REQUEST_NEW_PASSWORD',
    ON_REQUEST_NEW_PASSWORD_FAIL = 'ON_REQUEST_NEW_PASSWORD_FAIL',
    ON_REQUEST_NEW_PASSWORD_SUCCESS = 'ON_REQUEST_NEW_PASSWORD_SUCCESS',
    ON_REQUEST_PASSWORD_RESET = 'ON_REQUEST_PASSWORD_RESET',
    ON_REQUEST_PASSWORD_RESET_FAIL = 'ON_REQUEST_PASSWORD_RESET_FAIL',
    ON_REQUEST_PASSWORD_RESET_SUCCESS = 'ON_REQUEST_PASSWORD_RESET_SUCCESS',
    ON_REQUEST_SEND_EMAIL = 'ON_REQUEST_SEND_EMAIL',
    ON_REQUEST_SEND_EMAIL_FAIL = 'ON_REQUEST_SEND_EMAIL_FAIL',
    ON_REQUEST_SEND_EMAIL_SUCCESS = 'ON_REQUEST_SEND_EMAIL_SUCCESS',
    ON_REQUEST_UPDATE_USER = 'ON_REQUEST_UPDATE_USER',
    ON_REQUEST_UPDATE_USER_FAIL = 'ON_REQUEST_UPDATE_USER_FAIL',
    ON_REQUEST_UPDATE_USER_SUCCESS = 'ON_REQUEST_UPDATE_USER_SUCCESS',
    ON_SET_AUTH_STATE = 'ON_SET_AUTH_STATE',
}

export type AuthAction =
    | {
          type: AuthActionTypes.ON_REQUEST_LOGOUT;
      }
    | {
          type: AuthActionTypes.ON_REQUEST_SEND_EMAIL;
      }
    | {
          type: AuthActionTypes.ON_REQUEST_SEND_EMAIL_SUCCESS;
      }
    | {
          payload: string;
          type: AuthActionTypes.ON_REQUEST_SEND_EMAIL_FAIL;
      }
    | {
          type: AuthActionTypes.ON_REQUEST_PASSWORD_RESET;
      }
    | {
          type: AuthActionTypes.ON_REQUEST_PASSWORD_RESET_SUCCESS;
      }
    | {
          payload: string;
          type: AuthActionTypes.ON_REQUEST_PASSWORD_RESET_FAIL;
      }
    | {
          type: AuthActionTypes.ON_REQUEST_NEW_PASSWORD;
      }
    | {
          type: AuthActionTypes.ON_REQUEST_NEW_PASSWORD_SUCCESS;
      }
    | {
          payload: string;
          type: AuthActionTypes.ON_REQUEST_NEW_PASSWORD_FAIL;
      }
    | {
          type: AuthActionTypes.ON_REQUEST_UPDATE_USER;
      }
    | {
          payload: UserType;
          type: AuthActionTypes.ON_REQUEST_UPDATE_USER_SUCCESS;
      }
    | {
          payload: string;
          type: AuthActionTypes.ON_REQUEST_UPDATE_USER_FAIL;
      }
    | {
          payload: AuthState;
          type: AuthActionTypes.ON_SET_AUTH_STATE;
      };

export type UserType = {
    created_at: string;
    email: string;
    id: string;
    name: string;
    org_codes?: string[]; // Has to be an array of string because we don't know if array contains orgs we can acocunt for on the FE. use a type guard to narrow the type.
    phone_number: string;
    updated_at: string;
};

export type AuthState = {
    claims: Claims | null;
    error?: string;
    token: string | null;
    user: UserType | null;
};
