import { useState } from 'react';

import {
    isValidAccountNumber,
    isValidInstitutionNumber,
    isValidTransitNumber,
} from '../../../lib/string-helpers';
import { Button } from '../../UI/Button';
import { Error } from '../../UI/Error';
import { TextInput } from '../../UI/TextInput';
import { INVALID_FUNDING_ACCOUNT_ERROR_MESSAGE } from '../constants';
import styles from '../FundingAccountModal.module.scss';
import { FundingAccountProps } from './types';

export function FundingAccount({
    accountNumber,
    handleSave,
    institutionNumber,
    setAccountNumber,
    setInstitutionNumber,
    setTransitNumber,
    transitNumber,
}: FundingAccountProps) {
    const [error, setError] = useState<string>('');
    const handleUpdateFundingAccount = () => {
        if (
            isValidInstitutionNumber(institutionNumber) &&
            isValidTransitNumber(transitNumber) &&
            isValidAccountNumber(accountNumber)
        ) {
            setError('');
            handleSave();
        } else {
            setError(INVALID_FUNDING_ACCOUNT_ERROR_MESSAGE);
        }
    };

    return (
        <>
            <TextInput
                className={styles.sectionTextInput}
                label="Financial Institution Number"
                maxLength={3}
                setValue={setInstitutionNumber}
                value={institutionNumber}
            />
            <TextInput
                className={styles.sectionTextInput}
                label="Transit Number"
                maxLength={5}
                setValue={setTransitNumber}
                value={transitNumber}
            />
            <TextInput
                className={styles.sectionTextInput}
                label="Account Number"
                maxLength={12}
                setValue={setAccountNumber}
                value={accountNumber}
            />
            <Button
                className={styles.continueChipButton}
                colour="black"
                disabled={!institutionNumber || !transitNumber || !accountNumber}
                label="Update Funding Account"
                onClick={handleUpdateFundingAccount}
            />
            <Error message={error} />
        </>
    );
}
