import { useGlobalAuth } from '@keyliving/component-lib';
import { isTokenExpired } from '@keyliving/utils';
import { lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { NavBar } from '../components/NavBar';
import { PageWrapper } from '../components/PageWrapper';
import FullScreenSpinner from '../components/UI/FullScreenSpinner';
import AuthLayout from '../components/UI/layout/AuthLayout';
import { useHydration } from '../hooks/useHydration';
import { useSelector } from '../redux/hooks';
import { selectors as authSelectors } from '../redux/modules/auth';

const Login = lazy(() => import('./login'));
const PasswordReset = lazy(() => import('./password-reset'));
const NewPassword = lazy(() => import('./new-password'));
const Home = lazy(() => import('./home'));
const CoOwnershipInterest = lazy(() => import('./financial/co-ownership-interest'));
const EquityPurchase = lazy(() => import('./financial/equity-purchase'));
const MonthlyPayment = lazy(() => import('./financial/monthly-payment'));
const Help = lazy(() => import('./help'));
const MyAccount = lazy(() => import('./my-account'));

function PrivateRoute() {
    const { canMount } = useHydration();
    const { redirectToAuth } = useGlobalAuth();
    const { claims, user } = useSelector(authSelectors.selectAuthData);
    const tokenExpired = isTokenExpired(claims?.exp ?? null);
    const isLoggedIn = !!user && !tokenExpired;

    if (!canMount) {
        return <FullScreenSpinner />;
    }

    if (!isLoggedIn) {
        /**
         * Redirect them to the /login page, but save the current page they were
         * trying to access when they were redirected.
         */
        redirectToAuth({
            searchParams: {
                redirectUrl: window.location.href,
            },
        });
        return null;
    }

    return (
        <PageWrapper>
            <NavBar />
            <Outlet />;
        </PageWrapper>
    );
}

export default function AppRoutes(): JSX.Element {
    return (
        <Suspense fallback={<FullScreenSpinner />}>
            <Routes>
                <Route element={<Navigate to="/home" />} path="/" />

                <Route
                    element={
                        <AuthLayout>
                            <Outlet />
                        </AuthLayout>
                    }
                >
                    <Route element={<Login />} path="/login" />
                    <Route element={<PasswordReset />} path="/password-reset" />
                    <Route element={<NewPassword />} path="/new-password" />
                </Route>

                <Route element={<PrivateRoute />}>
                    <Route element={<Home />} path="/home" />
                    <Route path="financial">
                        <Route element={<Navigate to="co-ownership-interest" />} index />
                        <Route element={<CoOwnershipInterest />} path="co-ownership-interest" />
                        <Route element={<EquityPurchase />} path="equity-purchase" />
                        <Route element={<MonthlyPayment />} path="monthly-payment" />
                    </Route>

                    <Route element={<Help />} path="/help" />
                    <Route element={<MyAccount />} path="/my-account" />
                </Route>
            </Routes>
        </Suspense>
    );
}
