import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { reduxStore } from './redux/store';
import AppRoutes from './routes';
import muiCustomTheme from './theme/mui/muiTheme';

function App() {
    return (
        <Provider store={reduxStore}>
            <ThemeProvider theme={muiCustomTheme}>
                <BrowserRouter>
                    <AppRoutes />
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
