import { SVGProps } from 'react';

export default function Question({ height, width, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M683 372a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm0 1.12A10.88 10.88 0 1 0 693.88 384 10.89 10.89 0 0 0 683 373.12Zm0 16.09a1.05 1.05 0 1 1-1 1.05 1 1 0 0 1 1-1.05ZM680.64 377a3.78 3.78 0 0 1 3-.79 3.71 3.71 0 0 1 .71 7.05 1.44 1.44 0 0 0-.83 1.35v2.51a.56.56 0 1 1-1.12 0v-2.52a2.52 2.52 0 0 1 1.54-2.4 2.58 2.58 0 0 0-.53-4.91 2.66 2.66 0 0 0-2.1.56 2.61 2.61 0 0 0-.9 1.95 2.23 2.23 0 0 0 .15.86.56.56 0 0 1-.34.72.54.54 0 0 1-.71-.34 3.6 3.6 0 0 1 1.09-4.05Z"
                fill="currentColor"
                transform="translate(-671 -372)"
            />
        </svg>
    );
}
