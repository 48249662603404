export enum FundingActionTypes {
    ON_REQUEST_FUNDING_ACCOUNT = 'ON_REQUEST_FUNDING_ACCOUNT',
    ON_REQUEST_FUNDING_ACCOUNT_FAIL = 'ON_REQUEST_FUNDING_ACCOUNT_FAIL',
    ON_REQUEST_FUNDING_ACCOUNT_SUCCESS = 'ON_REQUEST_FUNDING_ACCOUNT_SUCCESS',
    ON_REQUEST_UPDATE_FUNDING_ACCOUNT = 'ON_REQUEST_UPDATE_FUNDING_ACCOUNT',
    ON_REQUEST_UPDATE_FUNDING_ACCOUNT_FAIL = 'ON_REQUEST_UPDATE_FUNDING_ACCOUNT_FAIL',
    ON_REQUEST_UPDATE_FUNDING_ACCOUNT_SUCCESS = 'ON_REQUEST_UPDATE_FUNDING_ACCOUNT_SUCCESS',
}

export type FundingAccountState = {
    account_number: string;
    error?: string;
    institution_name: string;
    institution_number: string;
    institution_short_name: string;
};
