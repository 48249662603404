import { LoadingRing } from '@keyliving/component-lib';

import styles from './FullScreenSpinner.module.scss';

export default function FullScreenSpinner() {
    return (
        <div className={styles.wrapper}>
            <LoadingRing />
        </div>
    );
}
