import formatNumber, { FormatNumberOptions } from '../formatNumber';

/**
 * Returns a correctly formatted string for currency values
 *
 * TODO: This was created before we had a formatNumber util.
 * Should refactor so no need to pass "digits" and just
 * pass "minimumFractionDigits" as an option property.
 *
 * @param {Number} input The number to format
 * @param {Number} digits The minimum number of decimal digits to use
 * @param {Intl.NumberFormatOptions} options Any valid option for the NumberFormat consructor. Src link below
 * @src https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @returns A formatted string
 */
export default function toMoney(
    input: number,
    digits: number = 2,
    options: FormatNumberOptions = {}
) {
    return formatNumber(input, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
        stripCommas: false,
        ...options,
    });
}
