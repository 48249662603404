import { AssetContract } from '@keyliving/shared-types';

export enum SuiteActionTypes {
    ON_REQUEST_SUITE = 'ON_REQUEST_SUITE',
    ON_REQUEST_SUITE_FAIL = 'ON_REQUEST_SUITE_FAIL',
    ON_REQUEST_SUITE_IMAGE = 'ON_REQUEST_SUITE_IMAGE',
    ON_REQUEST_SUITE_IMAGE_FAIL = 'ON_REQUEST_SUITE_IMAGE_FAIL',
    ON_REQUEST_SUITE_IMAGE_SUCCESS = 'ON_REQUEST_SUITE_IMAGE_SUCCESS',
    ON_REQUEST_SUITE_SUCCESS = 'ON_REQUEST_SUITE_SUCCESS',
    ON_REQUEST_SUITE_VALUES = 'ON_REQUEST_SUITE_VALUES',
    ON_REQUEST_SUITE_VALUES_FAIL = 'ON_REQUEST_SUITE_VALUES_FAIL',
    ON_REQUEST_SUITE_VALUES_SUCCESS = 'ON_REQUEST_SUITE_VALUES_SUCCESS',
}

// TODO: Add all actions
export type SuiteAction =
    | {
          type: SuiteActionTypes.ON_REQUEST_SUITE;
      }
    | {
          payload: SuiteState;
          type: SuiteActionTypes.ON_REQUEST_SUITE_SUCCESS;
      }
    | {
          payload: string;
          type: SuiteActionTypes.ON_REQUEST_SUITE_FAIL;
      }
    | {
          payload: SuiteValueHistoryType[];
          type: SuiteActionTypes.ON_REQUEST_SUITE_VALUES_SUCCESS;
      }
    | {
          payload: string;
          type: SuiteActionTypes.ON_REQUEST_SUITE_VALUES_FAIL;
      }
    | {
          payload: string;
          type: SuiteActionTypes.ON_REQUEST_SUITE_IMAGE_SUCCESS;
      }
    | {
          payload: string;
          type: SuiteActionTypes.ON_REQUEST_SUITE_IMAGE_FAIL;
      };

export type SuiteAddressType = {
    building_id: string;
    building_name: string;
    city: string;
    country: string;
    postal_code: string;
    region: string;
    street: string;
    unit: string | null;
};

export type SuiteDetailsType = {
    bathrooms: number;
    bedrooms: number;
    locker: number;
    parking: number;
    sqft: number;
};

export type SuiteValueHistoryType = {
    created_at: Date;
    value: number;
};

export type SuiteState = {
    address: SuiteAddressType;
    contracts?: AssetContract[]; // Should be guaranteed because we have a resident in the suite but to be consistent with the shared-types
    details: SuiteDetailsType;
    error?: string;
    id: string;
    image_folder_id: string;
    imageLink: string | null;
    value: number;
    valueHistory: SuiteValueHistoryType[];
};
