/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styles from './Checkbox.module.scss';
import { CheckboxProps } from './type';

export function Checkbox({ checked, className, toggleCheckBox }: CheckboxProps): JSX.Element {
    return (
        <div className={`${styles.wrapper} ${className}`} onClick={() => toggleCheckBox(!checked)}>
            {checked && <span>&#10003;</span>}
        </div>
    );
}
