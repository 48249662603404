import { SVGProps } from 'react';

export default function Wallet({ height, width, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={height}
            viewBox="0 0 24.04 20.77"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M687.89 373.61a3.19 3.19 0 0 1 3.19 3.19v.2a3.07 3.07 0 0 1-.75 2.06h2.14a2.57 2.57 0 0 1 2.55 2.51v10.32a2.52 2.52 0 0 1-2.51 2.51h-19a2.52 2.52 0 0 1-2.51-2.51V376.8a3.19 3.19 0 0 1 3.19-3.19h13.72Zm-15.78 5.82v12.45a1.4 1.4 0 0 0 1.38 1.38h19a1.36 1.36 0 0 0 1.39-1.38v-1.47h-4.47a1.8 1.8 0 0 1-1.8-1.8v-3.79a1.8 1.8 0 0 1 1.8-1.79h4.47v-1.47a1.4 1.4 0 0 0-1.39-1.38h-18.3a3.12 3.12 0 0 1-2.06-.75Zm21.75 4.72h-4.47a.67.67 0 0 0-.67.67v3.79a.67.67 0 0 0 .67.68h4.47Zm-2.37 1.76a.77.77 0 0 1 .79.79.79.79 0 1 1-1.57 0 .76.76 0 0 1 .78-.79Zm-3.56-11.17h-13.76a2.08 2.08 0 0 0-2.06 2.06v.2a2.08 2.08 0 0 0 2.06 2.06h13.76A2.08 2.08 0 0 0 690 377v-.19a2.08 2.08 0 0 0-2.06-2.06Z"
                data-name="Icon/Financial"
                fill="currentColor"
                transform="translate(-670.98 -373.61)"
            />
        </svg>
    );
}
