import { fetch } from '../../../lib/fetch';
import { AppThunk } from '../../store';
import { type FinanceState, CapTableEntry, FinanceActionTypes } from './types';

const onRequestMonthlySnapshots = () => ({
    type: FinanceActionTypes.ON_REQUEST_CURRENT_CAP_TABLE,
});

const onRequestMonthlySnapshotsSuccess = (
    payload: Pick<FinanceState, 'capTableHistory' | 'capTableCurrent'>
) => ({
    type: FinanceActionTypes.ON_REQUEST_CURRENT_CAP_TABLE_SUCCESS,
    payload,
});

const onRequestMonthlySnapshotsFail = (payload: string) => ({
    type: FinanceActionTypes.ON_REQUEST_CURRENT_CAP_TABLE_FAIL,
    payload,
});

const requestMonthlySnapshots = (): AppThunk<Promise<void>> => {
    return async (dispatch, getState) => {
        dispatch(onRequestMonthlySnapshots());

        const {
            auth: {
                user: { id: userId },
            },
            suite: { id: suiteId },
        } = getState();
        const params = new URLSearchParams({ userId, suiteId });

        return fetch(
            `${
                process.env.REACT_APP_API_SERVICE_ENDPOINT
            }/equity/monthly-snapshots?${params.toString()}`,
            { method: 'GET' }
        )
            .then(({ data }: { data: { snapshots: CapTableEntry[] } }) => {
                const currentEquitySnapshot =
                    data.snapshots?.length && data.snapshots.length > 0
                        ? data.snapshots[data.snapshots.length - 1]
                        : null;
                dispatch(
                    onRequestMonthlySnapshotsSuccess({
                        capTableHistory: data.snapshots,
                        capTableCurrent: currentEquitySnapshot,
                    })
                );
                //the last entry of the monthly snapshots is the current entry.
            })
            .catch(() => {
                dispatch(onRequestMonthlySnapshotsFail('Cannot retrieve financial history'));
            });
    };
};

export const actions = {
    requestMonthlySnapshots,
    onRequestMonthlySnapshots,
    onRequestMonthlySnapshotsSuccess,
    onRequestMonthlySnapshotsFail,
};
