import { useLocation } from 'react-router-dom';

import { NavTab } from '../../../components/NavTab';
import PageContainer from '../../../components/PageContainer';
import { getCurrentStage } from '../../../lib/helpers';
import { CURRENT_INVOICE, MONTHLY_PAYMENT_SUBPAGES, PAYMENT_HISTORY } from './constants';
import { CurrentInvoice } from './CurrentInvoice';
import styles from './Page.module.scss';
import { PaymentHistory } from './PaymentHistory';
import { PrimaryFunding } from './PrimaryFunding';

export default function Page(): JSX.Element {
    const location = useLocation();
    const currentStage = getCurrentStage(location, CURRENT_INVOICE);

    return (
        <>
            <div className={styles.header}>
                <div>Monthly Payment</div>
            </div>

            <NavTab currentStage={currentStage} pages={MONTHLY_PAYMENT_SUBPAGES} />

            <PageContainer>
                <div className={styles.body}>
                    <div>
                        {currentStage === CURRENT_INVOICE && <CurrentInvoice />}
                        {currentStage === PAYMENT_HISTORY && <PaymentHistory />}
                    </div>
                    <div>
                        <PrimaryFunding />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
