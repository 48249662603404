import { IconProps } from './types';

export default function QuestionIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M9 9a3 3 0 1 1 4 2.829 1.5 1.5 0 0 0-1 1.415v1.006"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M12 18a.375.375 0 0 1 0-.75M12 18a.375.375 0 0 0 0-.75"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25Z"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="1.5"
            />
        </svg>
    );
}
