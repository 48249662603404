/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as Icon from '../../../assets/icons';
import { Button } from '../Button';
import styles from './Modal.module.scss';
import { ModalProps } from './type';

export function Modal({
    children,
    content,
    header,
    setIsOpen,
    showCloseButton = true,
}: ModalProps): JSX.Element {
    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <div>{header}</div>
                    <div className={styles.close} onClick={() => setIsOpen(false)}>
                        <img alt="Cross" src={Icon.Cross} />
                    </div>
                </div>
                {children ? (
                    children
                ) : (
                    <div>
                        {content.map(({ text, type }, i) => {
                            if (type === 'title') {
                                return (
                                    <div className={styles.modalBodyTitle} key={`${text}-${i}`}>
                                        {text}
                                    </div>
                                );
                            }

                            return (
                                <div className={styles.modalBodyParagraph} key={`${text}-${i}`}>
                                    {text}
                                </div>
                            );
                        })}
                    </div>
                )}
                {showCloseButton && (
                    <div className={styles.modalFooter}>
                        <Button
                            className={styles.closeButton}
                            label="Close"
                            onClick={() => setIsOpen(false)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
