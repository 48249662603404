import { Reducer } from 'redux';

import type { AuthState } from './types';
import { type AuthAction, AuthActionTypes } from './types';

export const AUTH_INITIAL_STATE: AuthState = {
    user: null,
    token: null,
    claims: null,
};

export const authReducer: Reducer<AuthState, AuthAction> = (state = AUTH_INITIAL_STATE, action) => {
    switch (action.type) {
        case AuthActionTypes.ON_REQUEST_UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: '',
            };
        case AuthActionTypes.ON_REQUEST_NEW_PASSWORD_SUCCESS:
        case AuthActionTypes.ON_REQUEST_PASSWORD_RESET_SUCCESS:
        case AuthActionTypes.ON_REQUEST_SEND_EMAIL_SUCCESS:
            return {
                ...state,
                error: '',
            };
        case AuthActionTypes.ON_SET_AUTH_STATE:
            return {
                ...state,
                ...action.payload,
                error: '',
            };
        case AuthActionTypes.ON_REQUEST_NEW_PASSWORD_FAIL:
        case AuthActionTypes.ON_REQUEST_PASSWORD_RESET_FAIL:
        case AuthActionTypes.ON_REQUEST_SEND_EMAIL_FAIL:
        case AuthActionTypes.ON_REQUEST_UPDATE_USER_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case AuthActionTypes.ON_REQUEST_LOGOUT:
            return AUTH_INITIAL_STATE;
        default:
            return state;
    }
};
