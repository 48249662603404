import classNames from 'classnames/bind';

import { ModalIcon } from '../../UI/ModalIcon';
import styles from './SectionHeader.module.scss';
import { SectionHeaderProps } from './type';

const cx = classNames.bind(styles);

export function SectionHeader({
    className,
    header,
    icon,
    iconText,
    modalContent,
    modalHeader,
}: SectionHeaderProps): JSX.Element {
    return (
        <div className={cx('sectionHeader', className)}>
            <div className={styles.header}>{header}</div>
            {iconText && (
                <div className={styles.info}>
                    <div>{iconText}</div>
                    {icon && modalHeader && modalContent && (
                        <>
                            <div>|</div>
                            <ModalIcon content={modalContent} header={modalHeader} icon={icon} />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
