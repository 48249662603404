import { Section } from '../../../../components/Section';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import { SectionItem } from '../../../../components/Section/SectionItem';
import { Accordion } from '../../../../components/UI/Accordion';
import { formatDate } from '../../../../lib/date-helpers';
import { toMoney } from '../../../../lib/number-formatter';
import { useSelector } from '../../../../redux/hooks';
import {
    RESIDENCY_PAYMENT,
    selectors as invoiceSelectors,
} from '../../../../redux/modules/invoice';
import { selectors as suiteSelectors } from '../../../../redux/modules/suite';
import { INVOICE_TYPE } from '../constants';
import styles from './CurrentInvoice.module.scss';

export function CurrentInvoice() {
    const { street, unit } = useSelector(suiteSelectors.selectSuiteAddress);
    const { breakdown, created_at, due_date, reference_id, subtotal, taxes, total, type } =
        useSelector(invoiceSelectors.selectInvoiceData);

    const dynamicBreakdown = [
        {
            header: 'Invoice Total',
            items: [
                { name: 'SubTotal', amount: subtotal },
                { name: 'Tax', amount: taxes },
            ],
            total: total,
        },
    ];

    return (
        <Section className={styles.currentInvoiceWrapper}>
            <div className={styles.sectionHeader}>
                <SectionHeader
                    className={styles.sectionHeading}
                    header={`${INVOICE_TYPE[type]?.toUpperCase()} INVOICE`}
                />
            </div>
            <div className={styles.currentInvoiceContent}>
                <div className={styles.invoiceDetails}>
                    <SectionItem
                        className={styles.invoiceDetails__detail}
                        label="Invoice Number"
                        value={reference_id.toString()}
                        valueSize="small"
                    />
                    <SectionItem
                        className={styles.invoiceDetails__detail}
                        label="Invoice Date"
                        value={formatDate(created_at, 'yyyy/MM/dd')}
                        valueSize="small"
                    />
                    <SectionItem
                        className={styles.invoiceDetails__detail}
                        label="Debit Date"
                        value={formatDate(due_date, 'yyyy/MM/dd')}
                        valueSize="small"
                    />
                    <SectionItem
                        className={styles.invoiceDetails__detail}
                        label="Home Address"
                        value={unit === null ? street : `${unit} - ${street}`}
                        valueSize="small"
                    />
                </div>
                <div className={styles.totalPaymentWrapper}>
                    <SectionItem label="Total Payment" value={toMoney(total)} valueSize="large" />
                </div>
                <div className={styles.monthlyBreakdownWrapper}>
                    <div className={styles.sectionHeading}>
                        {INVOICE_TYPE[type]?.toUpperCase()} BREAKDOWN
                    </div>
                    {[...breakdown, ...dynamicBreakdown]
                        .filter(({ items }) => items.length)
                        .map(({ header, items, total }, index) => {
                            return (
                                <Accordion
                                    body={
                                        <div className={styles.bodyWrapper}>
                                            {items.map(({ amount, name }, index) => {
                                                return (
                                                    <div
                                                        className={`${styles.breakdownBody} ${
                                                            name ===
                                                            RESIDENCY_PAYMENT.OWNERSHIP_SAVINGS
                                                                ? styles.ownershipSavings
                                                                : ''
                                                        }`}
                                                        key={`${name}-${index}`}
                                                    >
                                                        <span>{name}</span>
                                                        <span>{toMoney(amount)}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                    header={
                                        <div className={styles.breakdownHeader}>
                                            <span>{header}</span>
                                            <span>{toMoney(total)}</span>
                                        </div>
                                    }
                                    key={`${header}-${index}`}
                                />
                            );
                        })}
                </div>
            </div>
        </Section>
    );
}
