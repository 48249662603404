/**
 * Documentation of options - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export interface FormatNumberOptions extends Intl.NumberFormatOptions {
    stripCommas?: boolean;
}

/**
 * Wrapper around the Intl.NumberFormat object
 *
 * NOTE: Default stripCommas to true for now until we have a more suitable font for numbers
 */
export default function formatNumber(value: number, options: FormatNumberOptions = {}) {
    //Note: this structure is triggering the rollup warning of:
    // https://rollupjs.org/troubleshooting/#error-this-is-undefined
    const { stripCommas = true, ...rest } = options;

    const formattedValue = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        ...rest,
    }).format(value);

    if (stripCommas) {
        return formattedValue.replace(/,/g, '');
    }

    return formattedValue;
}
