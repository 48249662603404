import { IconProps } from './types';

export default function TrendingUpwardIcon({ height = 9, width = 17, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 17 9"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M16.5 3.93V.5h-3.43"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m16.5.5-3.85 3.85c-.45.45-1.11.61-1.71.43L6.59 3.62c-.59-.16-1.22.01-1.65.44L.5 8.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
