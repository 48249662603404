import classNames from 'classnames/bind';

import styles from './FilterPanel.module.scss';
import { FilterPanelProps } from './type';

const cx = classNames.bind(styles);

export function FilterPanel({
    canClear,
    children,
    className,
    header,
    onClear,
    options,
    selected,
    setSelected,
}: FilterPanelProps): JSX.Element {
    return (
        <div className={cx('wrapper', className)}>
            <div className={styles.header}>
                <div className={styles.header__text}>{header}</div>
                {canClear && (
                    <button className={styles.clear} onClick={onClear} type="button">
                        Clear
                    </button>
                )}
            </div>
            {options ? (
                <div className={styles.options}>
                    {options.map((option, index) => (
                        <button
                            className={cx('option', {
                                'option--selected': option === selected,
                            })}
                            key={`${option}-${index}`}
                            onClick={() => {
                                if (setSelected) {
                                    setSelected(option);
                                }
                            }}
                            type="button"
                        >
                            {option}
                        </button>
                    ))}
                </div>
            ) : null}

            {children}
        </div>
    );
}
