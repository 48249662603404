import type { AuthState, UserType } from './types';

const selectAuthData = (state): AuthState => state.auth;
const selectAuthError = (state): string => state.auth.error;
const selectUserData = (state): UserType => state.auth.user;

export const selectors = {
    selectAuthData,
    selectUserData,
    selectAuthError,
};
