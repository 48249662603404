import { ContractCondition } from './Contract';

export enum OfferStatus {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    NOT_PROCEEDING = 'NOT_PROCEEDING',
    PENDING = 'PENDING',
}

export type OfferTable = {
    id: string;
    building_suite_id: string;
    created_at: string;
    updated_at: string;
    status: OfferStatus;
    date_submitted: string | null;
    approved_home_budget: number;
    max_home_budget: number;
    list_price: number;
    offer_price: number;
    final_purchase_price: number | null;
    building_unit: string | null;
    building_street: string;
    building_city: string;
    building_region: string;
    building_country: string;
    building_postal_code: string;
    notes: string | null;
};

export type OfferContractTable = {
    id: string;
    offer_id: string;
    building_suite_contract_conditions_id: string;
    value: number;
    created_at: string;
    updated_at: string;
};

export type OfferContract = OfferContractTable & {
    condition: Omit<ContractCondition, 'id'>; // conditions id is already included in OfferContractTable
};

export type Offer = OfferTable & {
    contracts: Omit<OfferContract, 'offer_id'>[]; // offer offer id is already included in OfferTable
};

export type CreateOfferParams = {
    buildingSuiteId: string;
    status: OfferStatus;
    approvedHomeBudget: number;
    maxHomeBudget: number;
    listPrice: number;
    offerPrice: number;
    buildingUnit?: string | null;
    finalPurchasePrice?: number | null;
    buildingStreet: string;
    buildingCity: string;
    buildingRegion: string;
    buildingCountry: string;
    buildingPostalCode: string;
    contracts: Array<{ buildingSuiteContractConditionsId: string; value: number }>;
    notes?: string | null;
};

export type UpdateOfferParams = {
    status: OfferStatus;
    approvedHomeBudget: number;
    maxHomeBudget: number;
    listPrice: number;
    offerPrice: number;
    buildingUnit?: string | null;
    finalPurchasePrice?: number | null;
    buildingStreet: string;
    buildingCity: string;
    buildingRegion: string;
    buildingCountry: string;
    buildingPostalCode: string;
    contracts: Array<{ buildingSuiteContractConditionsId: string; value: number }>;
    notes?: string | null;
};
