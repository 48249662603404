import { getOrCreateUUIDCookie } from '@keyliving/utils';
import crossFetch from 'cross-fetch';

import { reduxStore } from '../redux/store';

type FetchOptions = {
    body?: any;
    headers?: any;
    json?: boolean;
    method?: string;
    raw?: boolean;
};

export async function fetch<T = any>(
    url: string,
    { json = true, raw = false, ...opts }: FetchOptions = {}
): Promise<T> {
    const token = reduxStore.getState().auth.token;
    const xKeyAnonId = getOrCreateUUIDCookie();
    const defaultOpts = {
        ...opts,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-key-anon-id': xKeyAnonId,
            ...opts.headers,
        },
    };

    return crossFetch(url, defaultOpts)
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            if (raw) {
                return response;
            }

            return json ? response.json() : response.text();
        })
        .then((response) => {
            if (response.err) {
                throw new Error(response.msg);
            }
            return response;
        });
}
