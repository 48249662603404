export const CURRENT_INVOICE = 'CURRENT INVOICE';
export const PAYMENT_HISTORY = 'PAYMENT HISTORY';

export const MONTHLY_PAYMENT_SUBPAGES = [
    { page: 'Current Invoice', stage: CURRENT_INVOICE },
    { page: 'Payment History', stage: PAYMENT_HISTORY },
];

export enum INVOICE_STATUS_DESCRIPTION {
    CANCELLED = 'Cancelled',
    CHARGE_DISPUTE = 'Charge Dispute',
    OTHER = 'Other',
    PAID = 'Paid',
    PAST_DUE = 'Past Due',
    PAYMENT_FAILED = 'Payment Failed',
    PAYMENT_SCHEDULED = 'Payment Scheduled',
    PENDING_PAYMENT = 'Pending Payment',
}

export enum INVOICE_TYPE {
    INITIAL_INVESTMENT = 'Down Payment',
    MONTHLY = 'Monthly',
    OTHER = 'Other',
    PARTIAL_MONTH = 'Partial Month',
    TERMINATION = 'Termination',
}
