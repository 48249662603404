import { Claims } from '@keyliving/shared-types';
import { getOrCreateUUIDCookie, isTokenExpired } from '@keyliving/utils';
import { number, object, string } from 'zod';

const tokenPayload = object({
    claims: object({
        email: string().email(),
        exp: number(),
        iat: number(),
        id: string(), // user.id
        iss: string(),
        act: object({
            sub: string(), // user.id
        }).optional(),
    }),
});

/**
 * Check the token with our api to check it's validity, get the claims and also
 * parse with Zod to ensure proper typing.
 *
 * @param token The token to verify
 */
export async function verifyAuthToken(token: string): Promise<Claims> {
    try {
        const headers = new Headers({
            'Content-Type': 'application/json',
        });
        const keyAnonId = getOrCreateUUIDCookie();

        if (keyAnonId) {
            headers.append('x-key-anon-id', keyAnonId);
        }

        const response = await fetch(
            `${process.env.REACT_APP_IDENTITY_SERVICE_ENDPOINT}/users/verify`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify({ token }),
            }
        );

        if (!response.ok) {
            throw new Error('Token expired');
        }

        const { data } = await response.json();
        const { claims } = tokenPayload.parse(data);

        // ...token expiry should be handled by the api, but to be safe...
        const isExpired = isTokenExpired(claims.exp);

        if (isExpired) {
            throw new Error('Token expired');
        }

        /**
         * Not a fan of forcing types like this but until we set strictNullChecks to true,
         * zod can't do its job properly.
         *
         * TODO: turn on strictNullChecks
         */
        return Promise.resolve(claims as Claims);
    } catch (error) {
        return Promise.reject(error);
    }
}
