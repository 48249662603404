import { Document } from '@keyliving/shared-types';

import { fetch } from '../../../lib/fetch';
import { getImageLink } from '../../../lib/helpers';
import { AppThunk } from '../../store';
import { type SuiteState, type SuiteValueHistoryType, SuiteActionTypes } from './types';

const onRequestSuite = () => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE,
});

const onRequestSuiteSuccess = (payload: SuiteState) => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE_SUCCESS,
    payload,
});

const onRequestSuiteFail = (payload: string) => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE_FAIL,
    payload,
});

const onRequestSuiteValues = () => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE_VALUES,
});

const onRequestSuiteValuesSuccess = (payload: SuiteValueHistoryType[]) => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE_VALUES_SUCCESS,
    payload,
});

const onRequestSuiteValuesFail = (payload: string) => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE_VALUES_FAIL,
    payload,
});

const onRequestSuiteImage = () => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE_IMAGE,
});

const onRequestSuiteImageSuccess = (payload: string) => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE_IMAGE_SUCCESS,
    payload,
});

const onRequestSuiteImageFail = (payload: string) => ({
    type: SuiteActionTypes.ON_REQUEST_SUITE_IMAGE_FAIL,
    payload,
});

const requestSuiteData = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(onRequestSuite());

    const {
        auth: {
            user: { id: userId },
        },
    } = getState();
    const params = new URLSearchParams({ userId });

    try {
        const { data } = await fetch(
            `${process.env.REACT_APP_API_SERVICE_ENDPOINT}/suite?${params.toString()}`,
            {
                method: 'GET',
            }
        );
        const [suite]: SuiteState[] = Object.values(data);
        dispatch(onRequestSuiteSuccess(suite));
    } catch {
        dispatch(onRequestSuiteFail('Cannot find suite'));
    }
};

const requestSuiteValues = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(onRequestSuiteValues());

    const {
        suite: { id: suiteId },
    } = getState();
    const params = new URLSearchParams({ suiteId });

    return fetch(
        `${process.env.REACT_APP_API_SERVICE_ENDPOINT}/suite/values?${params.toString()}`,
        { method: 'GET' }
    )
        .then(({ data }) => {
            const valueHistory: SuiteValueHistoryType[] = Object.values(data);
            dispatch(onRequestSuiteValuesSuccess(valueHistory));
        })
        .catch(() => {
            dispatch(onRequestSuiteValuesFail('Cannot find suite values'));
        });
};

const requestSuiteImage = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(onRequestSuiteImage());

    const {
        suite: { image_folder_id: folderId },
    } = getState();
    const params = new URLSearchParams({ folderId, type: 'image/jpeg' });

    return fetch(
        `${
            process.env.REACT_APP_API_SERVICE_ENDPOINT
        }/documents/get-folder-content?${params.toString()}`,
        { method: 'GET' }
    )
        .then(({ data }) => {
            const sortedImages = Object.values(data).sort((a: any, b: any) => {
                try {
                    const aNameTmp = a.name.split('-')[2];
                    const aName = aNameTmp.split('.')[0];

                    const bNameTmp = b.name.split('-')[2];
                    const bName = bNameTmp.split('.')[0];
                    return aName > bName ? 1 : -1;
                } catch (err) {
                    return 1;
                }
            }) as Document[];

            // If we have images
            if (sortedImages.length > 0) {
                const imageIndex = sortedImages.length > 1 ? 1 : 0; // prefer the second image but use the floor plan if thats all there is
                const imageLink = getImageLink(sortedImages[imageIndex].id);
                dispatch(onRequestSuiteImageSuccess(imageLink));
            }

            dispatch(onRequestSuiteImageFail('Cannot retrieve suite image'));
        })
        .catch(() => {
            dispatch(onRequestSuiteImageFail('Cannot retrieve suite image'));
        });
};

export const actions = {
    requestSuiteData,
    onRequestSuite,
    onRequestSuiteSuccess,
    onRequestSuiteFail,
    requestSuiteValues,
    onRequestSuiteValues,
    onRequestSuiteValuesSuccess,
    onRequestSuiteValuesFail,
    requestSuiteImage,
    onRequestSuiteImage,
    onRequestSuiteImageSuccess,
    onRequestSuiteImageFail,
};
