export const colors = {
    YELLOW: '#FFD277',
    LIGHT_BLUE: '#AAE7E8',
    BLUE: '#0762C8',
    SKY_BLUE: '#49CDCF',
    DARK_BLUE: '#006ED1',
    LIGHT_GREEN: '#B0CF36',
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    GRID_GRAY: '#8097B1',
    GRAY: '#61798F',
    LIGHT_GRAY: '#8798AD',
    DARK_GRAY: '#314058',
};

export const raleway = 'raleway-regular';
export const raleway_bold = 'raleway-bold';
export const rawline = 'rawline-regular';
export const rawline_medium = 'rawline-medium';
export const fontSize = 12;

export const fontStyle = {
    fontFamily: raleway,
    fontSize,
    padding: 10,
    lineHeight: 16,
};

export const theme = {
    axis: {
        style: {
            axis: {
                stroke: colors.GRID_GRAY,
            },
            axisLabel: {
                ...fontStyle,
            },
            grid: {
                stroke: colors.GRID_GRAY,
                strokeDasharray: '3, 2',
                strokeWidth: '0.3',
            },
            tickLabels: {
                ...fontStyle,
                fill: colors.GRAY,
            },
        },
    },
    line: {
        style: {
            data: {
                strokeWidth: 2,
            },
        },
    },
    scatter: {
        style: {
            data: {
                fill: colors.WHITE,
                strokeWidth: 2,
                height: '15px',
                width: '15px',
            },
        },
        size: 4,
    },
    legends: {
        style: {
            labels: {
                fontSize,
                fontFamily: raleway_bold,
                alignSelf: 'center',
            },
        },
    },
};
