import { AnyAction, Reducer } from 'redux';

import type { FundingAccountState } from './types';
import { FundingActionTypes } from './types';

export const FUNDING_ACCOUNT_INITIAL_STATE: FundingAccountState = {
    account_number: '',
    institution_name: '',
    institution_short_name: '',
    institution_number: '',
};

export const fundingReducer: Reducer = (
    state: FundingAccountState = FUNDING_ACCOUNT_INITIAL_STATE,
    action: AnyAction
) => {
    switch (action.type) {
        case FundingActionTypes.ON_REQUEST_FUNDING_ACCOUNT_SUCCESS:
        case FundingActionTypes.ON_REQUEST_UPDATE_FUNDING_ACCOUNT_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case FundingActionTypes.ON_REQUEST_FUNDING_ACCOUNT_FAIL:
        case FundingActionTypes.ON_REQUEST_UPDATE_FUNDING_ACCOUNT_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
