import PageContainer from '../../components/PageContainer';
import styles from './Page.module.scss';
import { FundingAccount, ProfileInfo } from './Sections';

export default function Page(): JSX.Element {
    return (
        <>
            <div className={styles.header}>
                <div>My Account</div>
            </div>
            <PageContainer>
                <div className={styles.body}>
                    <ProfileInfo />
                    <FundingAccount />
                    {/* <MyDocs /> */}
                    {/* <Logout /> */}
                </div>
            </PageContainer>
        </>
    );
}
