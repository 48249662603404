import { useBoundingclientrect } from '@keyliving/component-lib';
import { format } from 'date-fns';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
    VictoryAxis,
    VictoryChart,
    VictoryGroup,
    VictoryLine,
    VictoryScatter,
    VictoryTooltip,
} from 'victory';

import type { SuiteValueHistoryType } from '../../../redux/modules/suite';
import { formatLabel, getLowerRange, getUpperRange } from '../lib/helpers';
import { colors, theme } from '../theme';
import { Filter, FILTER_VALUES } from './Filter';
import FlyOut from './FlyOut';
import styles from './PropertyValueChart.module.scss';

function filterValuesForTimeframe(values: { x: Date; y1: number }[], timeframe: FILTER_VALUES) {
    // All Values
    if (timeframe === FILTER_VALUES.ALL_TIME) {
        return values;
    }

    if (timeframe === FILTER_VALUES.LAST_TWELVE_MONTHS) {
        return values.slice(-12);
    }

    // Default to last 6 months
    return values.slice(-6);
}

interface PropertyValueChartProps {
    valueHistory: SuiteValueHistoryType[];
}

export function PropertyValueChart({ valueHistory }: PropertyValueChartProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const [canMount, setCanMount] = useState(false);
    const [timeframe, setTimeframe] = useState<FILTER_VALUES>(FILTER_VALUES.LAST_SIX_MONTHS);
    const { width: containerWidth } = useBoundingclientrect(ref);

    const filteredData = useMemo(() => {
        const sortedData = valueHistory
            .map(({ created_at, value }) => {
                return {
                    date: new Date(created_at),
                    value,
                };
            })
            .sort((a, b) => {
                return a.date.getTime() - b.date.getTime();
            })
            .map(({ date, value }) => {
                // return { x: formatDate(date, 'MMM yyyy'), y1: value };
                return { x: date, y1: value };
            });

        return filterValuesForTimeframe(sortedData, timeframe);
    }, [timeframe, valueHistory]);

    const lowerRange = getLowerRange(filteredData, {
        offset: 10_000,
        accessor({ x, ...rest }) {
            return Math.min(...Object.values(rest));
        },
    });
    const upperRange = getUpperRange(filteredData, {
        offset: 10_000,
        accessor({ x, ...rest }) {
            return Math.max(...Object.values(rest));
        },
    });
    const propertyValue = filteredData.map(({ x, y1 }) => ({ x, y: y1 }));

    useLayoutEffect(() => {
        /**
         * Need to set canMount after layout calculated and
         * before rendering the chart because on initial
         * render, the container width is zero.
         */
        setCanMount(true);
    }, []);

    return (
        <div className={styles.wrapper} ref={ref}>
            {canMount ? (
                <>
                    <Filter selected={timeframe} setSelected={setTimeframe} />
                    <VictoryChart
                        domain={{ y: [lowerRange, upperRange] }}
                        height={400}
                        padding={{ left: 80, bottom: 40, right: 20, top: 10 }}
                        theme={theme}
                        width={containerWidth}
                    >
                        <VictoryAxis
                            fixLabelOverlap
                            scale="time"
                            style={{ grid: { stroke: 'none' } }}
                            tickFormat={(x) => formatLabel.formatXAxis(format(x, 'MMM yyyy'))}
                        />
                        <VictoryAxis
                            dependentAxis
                            tickFormat={(t) => '$' + new Intl.NumberFormat().format(t)}
                        />
                        <VictoryGroup data={propertyValue}>
                            <VictoryLine
                                data={propertyValue}
                                style={{ data: { stroke: colors.LIGHT_GREEN } }}
                            />
                            <VictoryScatter
                                labelComponent={<VictoryTooltip flyoutComponent={<FlyOut />} />}
                                labels={() => ''}
                                style={{ data: { stroke: colors.LIGHT_GREEN } }}
                            />
                        </VictoryGroup>
                    </VictoryChart>
                </>
            ) : null}
        </div>
    );
}
