import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as Datepicker } from '@mui/x-date-pickers/DatePicker';

import { DatePickerProps } from './type';

export function DatePicker({
    value,
    label,
    setValue,
    minDate = null,
    onClose,
    onError,
    maxDate = new Date(),
}: DatePickerProps): JSX.Element {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Datepicker
                label={label}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(newValue) => setValue(newValue)}
                onClose={onClose}
                onError={onError}
                renderInput={(params) => <TextField {...params} />}
                value={value}
            />
        </LocalizationProvider>
    );
}
