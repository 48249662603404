import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import styles from './SectionItem.module.scss';
import { SectionItemProps } from './type';

const cx = classNames.bind(styles);

export function SectionItem({
    caption,
    className,
    image,
    label,
    link,
    percent,
    value,
    valueSize = 'medium',
}: SectionItemProps): JSX.Element {
    const isMoney = value.includes('$');
    const isNegative = value.includes('-');

    return (
        <div className={cx('wrapper', className)}>
            <div className={styles.label}>{label}</div>
            <div className={styles.row}>
                {(() => {
                    if (isMoney) {
                        return (
                            <>
                                {image && <img alt="" src={image} />}
                                <span
                                    className={cx({
                                        valueSmall: valueSize === 'small',
                                        valueMedium: valueSize === 'medium',
                                        valueLarge: valueSize === 'large',
                                    })}
                                >
                                    {value}
                                </span>
                            </>
                        );
                    }

                    return (
                        <span
                            className={cx({
                                valueSmall: valueSize === 'small',
                                valueMedium: valueSize === 'medium',
                                valueLarge: valueSize === 'large',
                            })}
                        >
                            {image && <img alt="" src={image} />}
                            {value}
                        </span>
                    );
                })()}
            </div>
            {percent && (
                <div className={isNegative ? styles.negativePercent : styles.positivePercent}>
                    {isNegative ? '↘' : '↗'} {percent}
                </div>
            )}
            {caption && !link && <div className={styles.caption}>{caption}</div>}
            {link && caption && (
                <Link className={styles.link} to={link}>
                    {caption}
                </Link>
            )}
        </div>
    );
}
