import { AnyAction, Reducer } from 'redux';

import type { FinanceState } from './types';
import { FinanceActionTypes } from './types';

export const FINANCE_INITIAL_STATE: FinanceState = {
    capTableCurrent: null,
    capTableHistory: [],
};

export const financeReducer: Reducer = (
    state: FinanceState = FINANCE_INITIAL_STATE,
    action: AnyAction
) => {
    switch (action.type) {
        case FinanceActionTypes.ON_REQUEST_CURRENT_CAP_TABLE_SUCCESS:
        case FinanceActionTypes.ON_REQUEST_OWNERSHIP_LEDGER_SUCCESS:
        case FinanceActionTypes.ON_REQUEST_MONTHLY_SNAPSHOTS_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case FinanceActionTypes.ON_REQUEST_CURRENT_CAP_TABLE_FAIL:
        case FinanceActionTypes.ON_REQUEST_OWNERSHIP_LEDGER_FAIL:
        case FinanceActionTypes.ON_REQUEST_MONTHLY_SNAPSHOTS_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
