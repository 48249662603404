import { toMoney } from '@keyliving/utils';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EquityPerformanceChart } from '../../../../components/Charts/EquityPerformanceChart';
import { Filter, FILTER_VALUES } from '../../../../components/Charts/Filter';
import {
    filterValuesForTimeframe,
    generateIncrementalXAxisMonths,
    getEquityPerformanceData,
    getInclusiveCutOffDate,
    getLowerRange,
} from '../../../../components/Charts/lib/helpers';
import { Section } from '../../../../components/Section';
import { SectionDivider } from '../../../../components/Section/SectionDivider';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import { Button } from '../../../../components/UI/Button';
import { useSelector } from '../../../../redux/hooks';
import { selectors as financeSelectors } from '../../../../redux/modules/finance';
import ChangeSinceInception from './ChangeSinceInception';
import styles from './EquityPerformance.module.scss';

function EquityPerformanceComplete(): JSX.Element {
    const [timeframe, setTimeframe] = useState<FILTER_VALUES>(FILTER_VALUES.LAST_SIX_MONTHS);
    const navigate = useNavigate();
    const currentSnapshot = useSelector(financeSelectors.selectFinancialCapTableCurrent);
    const financialSummarySnapshots = useSelector(financeSelectors.selectFinancialCapTableHistory);

    const snapshotCurrentEquityValue = currentSnapshot?.current_equity_value ?? null;
    const totalCofinancingAllocated = currentSnapshot?.total_co_financing_allocated ?? null;
    const totalEquityPurchased = currentSnapshot?.total_equity_purchased ?? null;

    const equityPerformance = useMemo(() => {
        const equityPerformanceData = getEquityPerformanceData(financialSummarySnapshots);

        return filterValuesForTimeframe(equityPerformanceData, {
            timeframe,
            accessor(values) {
                return values.date;
            },
        }).sort((a, b) => {
            return a.date.getTime() - b.date.getTime();
        });
    }, [financialSummarySnapshots, timeframe]);

    const xAxisTicks = useMemo(() => {
        // Get the cut off date based on how far back we want to show on the graph
        let cutOffDate = getInclusiveCutOffDate(timeframe);

        // If we are showing ALL of our data, get the oldest date
        if (timeframe === FILTER_VALUES.ALL_TIME) {
            const lowerRange = getLowerRange(equityPerformance, {
                offset: 0,
                accessor({ date }) {
                    return date.getTime();
                },
            });

            /**
             * There is a chance we have an empty array of data. In that case, just
             * show 12 empty months.
             */
            cutOffDate =
                lowerRange === Number.POSITIVE_INFINITY
                    ? getInclusiveCutOffDate(FILTER_VALUES.LAST_TWELVE_MONTHS)
                    : new Date(lowerRange);
        }

        return generateIncrementalXAxisMonths({ start: cutOffDate });
    }, [equityPerformance, timeframe]);

    return (
        <Section>
            <SectionHeader header="EQUITY PERFORMANCE" />
            {totalEquityPurchased !== null &&
            snapshotCurrentEquityValue !== null &&
            totalCofinancingAllocated !== null ? (
                <p>
                    Current Key Equity is{' '}
                    <span className={styles.equity}>
                        {toMoney(snapshotCurrentEquityValue - totalCofinancingAllocated, 0, {
                            maximumFractionDigits: 0,
                        })}
                    </span>{' '}
                    with a Net Investment of{' '}
                    <span className={styles.investment}>
                        {toMoney(totalEquityPurchased, 0, {
                            maximumFractionDigits: 0,
                        })}
                    </span>{' '}
                    as of {format(new Date(), 'MMM dd, yyyy')}.
                </p>
            ) : null}

            <div className={styles.wrapper}>
                <div>
                    <div className={styles.filter}>
                        <Filter selected={timeframe} setSelected={setTimeframe} />
                    </div>
                    <EquityPerformanceChart data={equityPerformance} xAxisMonths={xAxisTicks} />
                </div>
                <div>
                    <div className={styles.legend}>
                        {/* <div className={cx('legend__icon', 'legend__icon--equity')} /> */}
                        <div className={styles.legend__content}>
                            <div className={styles.legend__title}>Equity Value</div>
                            <p>The total value of Key home equity in your account.</p>
                        </div>
                    </div>
                    <div className={styles.legend}>
                        {/* <div className={cx('legend__icon', 'legend__icon--investment')} /> */}
                        <div className={styles.legend__content}>
                            <div className={styles.legend__title}>Equity Purchased</div>
                            <p>
                                The total amount you&apos;ve invested minus any sales or transfers
                                of equity in your account.
                            </p>
                        </div>
                    </div>

                    <SectionDivider className={styles.sectionDivider} />

                    <div className={styles.change}>
                        <ChangeSinceInception
                            currentEquityValue={snapshotCurrentEquityValue}
                            currentTotalCofinancingAllocated={totalCofinancingAllocated}
                            totalEquityPurchased={totalEquityPurchased}
                        />
                        {/*
                        <SectionItem
                            label="Change Since Inception"
                            percent={toPercent(percentageSinceInception)}
                            value={toMoney(amountSinceInception)}
                            valueSize="medium"
                        />
                    */}
                    </div>

                    <div className={styles.buttonWrapper}>
                        <Button
                            className={styles.button}
                            label="Buy Equity"
                            onClick={() => navigate('/financial/equity-purchase')}
                        />
                    </div>
                </div>
            </div>
        </Section>
    );
}

export function EquityPerformance() {
    const transactions = useSelector(financeSelectors.selectFinancialCapTableHistory);
    if (transactions.length === 0) return <></>;
    return <EquityPerformanceComplete />;
}
