import { useState } from 'react';

import { Button } from '../../../components/UI/Button';
import { Dropdown } from '../../../components/UI/Dropdown';
import { Options } from '../../../components/UI/Dropdown/type';
import { TextInput } from '../../../components/UI/TextInput';
import { useDispatch } from '../../../redux/hooks';
import { actions as authActions } from '../../../redux/modules/auth';
import { CONTACT_KEY_DROPDOWN_OPTIONS } from '../constants';
import styles from './ContactKey.module.scss';

export function ContactKey(): JSX.Element {
    const dispatch = useDispatch();
    const [subject, setSubject] = useState<Options>(CONTACT_KEY_DROPDOWN_OPTIONS[0]);
    const [message, setMessage] = useState<string>();
    const [isSent, setIsSent] = useState<boolean>(false);

    const handleSubmit = () => {
        dispatch(authActions.requestSendEmail(subject.value, message));

        setIsSent(true);
    };

    return (
        <div className={styles.contactFormWrapper}>
            <div className={styles.contactFormContent}>
                <div className={styles.heading}>CONTACT KEY</div>
                <p>
                    If you didn&rsquo;t find your answer in our FAQs, please feel free to contact
                    Key below. Our team will respond to you as soon as possible.
                </p>
                <Dropdown
                    options={CONTACT_KEY_DROPDOWN_OPTIONS}
                    placeholder="Subject"
                    selected={subject}
                    setSelected={(val) => setSubject(val)}
                />
                <TextInput
                    className={styles.textInput}
                    multiline
                    placeholder="Question, comment or issue"
                    setValue={(val) => setMessage(val)}
                    value={message}
                />
                {/*Comment out for initial release*/}
                {/*                <span>
                    <Button label='Upload File' onClick={()=>{}} className={styles.uploadButton}/>
                    No file attached
                </span>*/}
                <Button
                    className={styles.submitButton}
                    colour={'black'}
                    disabled={isSent}
                    label="Submit"
                    onClick={handleSubmit}
                />
                {isSent && <p>Your message has been successfully sent.</p>}
            </div>
        </div>
    );
}
