/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';

import { Modal } from '../Modal';
import styles from './ModalIcon.module.scss';
import { ModalIconProps } from './type';

export function ModalIcon({ content, header, icon }: ModalIconProps): JSX.Element {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <img alt="Icon" className={styles.icon} onClick={() => setIsOpen(true)} src={icon} />
            {isOpen && <Modal content={content} header={header} setIsOpen={setIsOpen} />}
        </>
    );
}
