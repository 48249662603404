import { useClickOutside } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { useRef, useState } from 'react';

import { Down } from '../../../assets/icons';
import styles from './Dropdown.module.scss';
import { DropdownProps, Options } from './type';

const cx = classNames.bind(styles);

export function Dropdown({
    className,
    options,
    placeholder,
    selected,
    setSelected,
}: DropdownProps): JSX.Element {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useClickOutside(dropdownRef, () => {
        setIsOpen(false);
    });

    return (
        <div className={cx('dropdown', className)} ref={dropdownRef}>
            <button className={styles.dropdownButton} onClick={() => setIsOpen(!isOpen)}>
                <div>{selected && selected.label.length > 0 ? selected.label : placeholder}</div>
                <img alt="Down Arrow" src={Down} />
            </button>
            {isOpen && (
                <div className={styles.dropdownContent}>
                    {options.map((option: Options, i: number) => (
                        <button
                            className={styles.option}
                            key={`${option.value}-${i}`}
                            onClick={() => setSelected(option)}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}
