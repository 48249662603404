import classNames from 'classnames/bind';
import { useMemo } from 'react';
import {
    Accept,
    DropEvent,
    DropzoneOptions,
    FileError,
    FileRejection,
    useDropzone,
} from 'react-dropzone';

import { AddFileIcon, AddImageIcon } from '../../icons';
import LoadingRing from '../LoadingRing';
import ProgressRing from '../ProgressRing';
import classes from './FileDropArea.module.scss';

const cx = classNames.bind(classes);

const iconMap = {
    file: AddFileIcon,
    image: AddImageIcon,
};

export type IconType = keyof typeof iconMap;

export interface FileDropAreaProps extends Omit<DropzoneOptions, 'validator' | 'accept'> {
    /** The value must be an object with a common [MIME type](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types) as keys and an array of file extensions as values */
    acceptFileTypes?: Accept;
    /** Id of the element that contains the description for the field. Most likely the errorId */
    'aria-describedby': string;
    /** Add additional css classes */
    className?: string;
    /** Is the drop area disabled? */
    disabled?: boolean;
    /** Validation function called on each file */
    fileValidator?: (file: File) => FileError | FileError[] | null;
    /** Display an icon to guide users about what type of file they should upload */
    iconFor?: IconType;
    /** Is the drop area in an invalid state */
    isInvalid?: boolean;
    /** Should we show a loading or progress indicator */
    isUploading?: boolean;
    /** Function that's passed the files that pass/fail validation */
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
    /** When uploading, show percent complete of progress ring */
    percentComplete?: number;
    /** A bold label that should instruct users what to do */
    primaryLabel?: string;
    /** Supporting hint for users */
    secondaryLabel?: string;
    /** Show the upload progress ring */
    showProgress?: boolean;
}

export default function FileDropArea({
    acceptFileTypes,
    'aria-describedby': describedby,
    className,
    disabled,
    fileValidator,
    iconFor = 'file',
    isInvalid,
    isUploading,
    multiple,
    onDrop,
    percentComplete = 0,
    primaryLabel,
    secondaryLabel,
    showProgress,
    ...rest
}: FileDropAreaProps) {
    const Icon = iconMap[iconFor];
    const primarylabelDefault = multiple ? 'Select files to import' : 'Select a file to import';
    const secondarylabelDefault = multiple
        ? 'or drag and drop them here'
        : 'or drag and drop it here';
    const heading = primaryLabel ?? primarylabelDefault;
    const subHeading = secondaryLabel ?? secondarylabelDefault;

    const acceptedFileExtensions = useMemo(() => {
        if (!acceptFileTypes) {
            return undefined;
        }

        const list = Object.values(acceptFileTypes);
        const flatList = list.reduce((acc, current) => {
            return acc.concat(current);
        }, []);

        return flatList.join(', ');
    }, [acceptFileTypes]);

    const { getInputProps, getRootProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptFileTypes,
        validator: fileValidator,
        disabled,
        ...rest,
    });

    return (
        <>
            <div className={cx(classes.wrapper, className)} data-testid="zone-wrapper">
                <div
                    {...getRootProps({
                        className: cx(classes.zone, {
                            'zone--active': isDragActive,
                            'zone--disabled': disabled,
                        }),
                        role: 'button',
                        'aria-label': [heading, acceptedFileExtensions].join(' '),
                        'aria-describedby': describedby,
                        'aria-invalid': isInvalid,
                    })}
                >
                    <input {...getInputProps()} />

                    {isUploading ? (
                        <>
                            <div className={classes.progress}>
                                {showProgress ? (
                                    <ProgressRing percentage={percentComplete} />
                                ) : (
                                    <LoadingRing strokeWidth={4} width={80} />
                                )}
                            </div>
                            <div className={classes.heading}>Uploading...</div>
                        </>
                    ) : (
                        <>
                            <Icon
                                aria-hidden={true}
                                className={classes.icon}
                                height={48}
                                width={48}
                            />
                            <p>
                                <span className={classes.heading}>{heading}</span>
                                <br /> {subHeading}
                            </p>
                            {acceptedFileExtensions && (
                                <p className={classes['extension-list']}>
                                    Only {acceptedFileExtensions}
                                </p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
