import { IconProps } from './types';

export default function TrendingDownwardIcon({ height = 9, width = 17, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 17 9"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M13.07 8.5h3.43V5.07"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m.5.5 4.44 4.44c.43.43 1.06.6 1.65.44l4.35-1.16c.6-.18 1.26-.02 1.71.43L16.5 8.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
