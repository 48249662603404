import { PhoneNumberUtil } from 'google-libphonenumber';

import { VALID_CANADIAN_INSTITUTION_NUMBERS } from '../constants';

export const isValidEmail = (email: string): boolean => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
};

export const validateEmail = (email: string): string | void => {
    const isValid = isValidEmail(email);

    if (!isValid) {
        return 'Please enter a valid email address';
    }
};

export const isValidPhoneNumberRegex = (str: string): boolean => {
    const regex = /^\d{10}$/;
    return regex.test(str);
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const validatePhoneNumber = (str: string): string | void => {
    const isValidRegex = isValidPhoneNumberRegex(str);

    if (!isValidRegex) {
        return 'Please enter a valid 10 digit phone number';
    }

    const isValidCA = phoneUtil.isValidNumberForRegion(phoneUtil.parse(str, 'CA'), 'CA');
    const isValidUS = phoneUtil.isValidNumberForRegion(phoneUtil.parse(str, 'US'), 'US');

    if (!isValidCA && !isValidUS) {
        return 'Please enter a valid Canadian or American phone number';
    }
};

export const isNumericString = (str: string): boolean => {
    const regex = /^[0-9]+$/;
    return regex.test(str);
};

export const isValidInstitutionNumber = (str: string): boolean => {
    return (
        isNumericString(str) &&
        str.length === 3 &&
        VALID_CANADIAN_INSTITUTION_NUMBERS.map(
            ({ institutionNumber }) => institutionNumber
        ).includes(str)
    );
};

export const isValidTransitNumber = (str: string): boolean => {
    return isNumericString(str) && str.length === 5;
};

export const isValidAccountNumber = (str: string): boolean => {
    return isNumericString(str) && str.length >= 7 && str.length <= 12;
};
