import { CapTableEntry } from '../redux/modules/finance';
import { BreakdownType, InvoiceLineItemType } from '../redux/modules/invoice';

export const getCurrentStage = (location, fallback: string): string =>
    location?.state?.currentStage || fallback;

export const getUrlParams = (location): URLSearchParams => {
    return new URLSearchParams(location.search);
};

export const getFilteredTransactions = (
    transactions: CapTableEntry[],
    fromDate?: Date,
    toDate?: Date
): CapTableEntry[] => {
    let result = transactions;
    if (fromDate && toDate) {
        result = result.filter(
            (transaction) =>
                new Date(transaction.invoice_date).getTime() >= fromDate.setHours(0, 0, 0, 0) &&
                new Date(transaction.invoice_date).getTime() <= toDate.setHours(23, 59, 59, 59)
        );
    }

    return result;
};

export const getInvoiceBreakdown = (
    header: string,
    section: any,
    invoiceLineItems: InvoiceLineItemType[]
): BreakdownType => {
    let total = 0;
    const items = [];
    invoiceLineItems.forEach(({ amount, description, type }) => {
        if (Object.values(section).includes(type)) {
            total += amount;
            items.push({ name: description, amount });
        }
    });
    return { header, total, items };
};

export const getImageLink = (id: string): string =>
    `https://drive.google.com/uc?export=view&id=${id}`;

export const roundToDecimal = (value: number, decimal: number): number => {
    const factor = Math.pow(10, decimal);
    return Math.round(value * factor) / factor;
};

export const isValidCurrencyAmount = (amount: string): boolean => {
    const regex = /^\d+(?:\.?\d{0,2})$/;
    return regex.test(amount);
};
