export enum FIRM_CALCULATOR_VERSION {
    V1 = '1.0',
}

export type FirmCalculatorConstantsV1 = {
    annual_equity_growth_rate: number;
    annual_home_appreciation_rate: number;
    monthly_occupancy_payment_bps: number;
    target_equity_percentage: number;
    term_length_years: number;
};

export type FirmCalculatorUserInputsV1 = {
    down_payment_percentage: number;
    home_value: number;
};

export type FirmCalculatorInputs = FirmCalculatorUserInputsV1 & FirmCalculatorConstantsV1;

export type FirmCalculatorResult = {
    downPaymentValue: number;
    finalEquityValue: number;
    finalHomeValue: number;
    monthlyEquityPayment: number;
    monthlyOccupancyPayment: number;
};

/**
 * Api will return default values so we can
 * assume all input values are present
 */
export type FirmCalculatorResultWithInput = {
    input: FirmCalculatorInputs;
    result: FirmCalculatorResult;
};

export interface FirmCalculationRequestBodyV1 extends FirmCalculatorUserInputsV1 {
    version: FIRM_CALCULATOR_VERSION.V1;
}

export type FirmCalculationRequestBody = FirmCalculationRequestBodyV1;
