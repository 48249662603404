import { useMemo, useState } from 'react';

import { Section } from '../../../../components/Section';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import { SectionItem } from '../../../../components/Section/SectionItem';
import { Button } from '../../../../components/UI/Button';
import { Checkbox } from '../../../../components/UI/Checkbox';
import { TextInput } from '../../../../components/UI/TextInput';
import { isValidCurrencyAmount, roundToDecimal } from '../../../../lib/helpers';
import { useDispatch, useSelector } from '../../../../redux/hooks';
import { actions, selectors } from '../../../../redux/modules/equity';
import { APPROVED, INVALID_AMOUNT_ERROR_MESSAGE } from '../constants';
import styles from './Order.module.scss';

export function Order({ nextStage, setNextStage }): JSX.Element {
    const dispatch = useDispatch();
    const { error } = useSelector(selectors.selectEquityPurchaseOrder);
    const [purchaseAmount, setPurchaseAmount] = useState<string>('');
    const [isTransactionAuthorized, setIsTransactionAuthorized] = useState<boolean>(false);
    const [isEquityPurchaseSubmitted, setIsEquityPurchaseSubmitted] = useState<boolean>(false);
    const [isRequestPurchaseFail, setIsRequestPurchaseFail] = useState<boolean>(false);

    const adminFee = useMemo<string>(() => {
        if (purchaseAmount.length > 0 && Number(purchaseAmount) > 0) {
            const fee = roundToDecimal(Number(purchaseAmount) * 0.01, 2);
            return `$ ${fee}`;
        }

        return '$ 0';
    }, [purchaseAmount]);

    const debitAmount = useMemo<string>(() => {
        if (purchaseAmount.length > 0 && Number(purchaseAmount) > 0) {
            const fee = roundToDecimal(Number(purchaseAmount) + Number(purchaseAmount) * 0.01, 2);
            return `$ ${fee}`;
        }

        return '$ 0';
    }, [purchaseAmount]);

    const handleSubmitEquityOrder = async () => {
        if (isTransactionAuthorized) {
            setIsEquityPurchaseSubmitted(true);

            const isRequestPurchaseSuccess = await dispatch(
                actions.requestEquityPurchase(roundToDecimal(+purchaseAmount, 2), APPROVED)
            );

            if (isRequestPurchaseSuccess) {
                setNextStage(nextStage);
            } else {
                setIsEquityPurchaseSubmitted(false);
                setIsRequestPurchaseFail(true);
            }
        }
    };

    return (
        <Section>
            <SectionHeader header="EQUITY PURCHASE ORDER" />
            <div className="content">
                <p>
                    You can purchase additional equity based on the current month&apos;s Suite Value
                    to increase your Home Equity. Purchase period end time is the 24th of the month
                    at 12PM ET.
                </p>
                <p>
                    The additional purchase amount will be added to your Monthly Invoice on the 25th
                    of the month and debited on the{' '}
                    <span className={styles.bold}>1st of the following month</span>
                </p>
                <p>
                    With every Equity Purchase, you own more of your suite so you will also enjoy
                    savings from an increase to your Ownership Benefit. You will see this reflected
                    in your next month&apos;s invoice.
                </p>
            </div>

            <TextInput
                className={styles.textInput}
                label="Equity Purchase Amount"
                setValue={setPurchaseAmount}
                step={100}
                type="number"
                value={purchaseAmount}
            />

            <div className={styles.adminFeeContainer}>
                <SectionItem label="Equity Admin Fee (1.0%)" value={adminFee} valueSize="medium" />
            </div>

            <hr className={styles.divider} />

            {purchaseAmount && !isValidCurrencyAmount(purchaseAmount) && (
                <span className={styles.errMsg}>{INVALID_AMOUNT_ERROR_MESSAGE}</span>
            )}

            <div className={styles.totalAmountContainer}>
                <SectionItem label="Total Debit Amount" value={debitAmount} valueSize="medium" />
            </div>

            <div className={styles.row}>
                <div>
                    <Checkbox
                        checked={isTransactionAuthorized}
                        className={styles.checkbox}
                        toggleCheckBox={setIsTransactionAuthorized}
                    />
                </div>
                <p>
                    I authorize the transaction to be executed and the Total Debit Amount to be
                    debited from my Funding account on the 1st of the following month.
                </p>
            </div>

            <div className={styles.buttonContainer}>
                {isRequestPurchaseFail && <span className={styles.errMsg}>{error}</span>}

                <Button
                    className={styles.button}
                    colour="black"
                    disabled={
                        !isTransactionAuthorized ||
                        !purchaseAmount ||
                        !isValidCurrencyAmount(purchaseAmount)
                    }
                    label={isEquityPurchaseSubmitted ? 'Submitting' : 'Buy Equity'}
                    onClick={handleSubmitEquityOrder}
                />
            </div>
        </Section>
    );
}
