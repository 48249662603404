import classNames from 'classnames/bind';

import styles from './SectionDivider.module.scss';
import { SectionDividerProps } from './type';

const cx = classNames.bind(styles);

export function SectionDivider({ className }: SectionDividerProps): JSX.Element {
    return <div className={cx('sectionDivider', className)} />;
}
