import { Accordion } from '../../../components/UI/Accordion';
import { FAQ_SECTION } from '../constants';
import styles from './Faq.module.scss';

export function Faq(): JSX.Element {
    return (
        <div className={styles.FaqWrapper}>
            <div className={styles.FaqFormHeading}>FREQUENTLY ASKED QUESTIONS</div>
            <div className={styles.faqFormContent}>
                {FAQ_SECTION.map(({ heading, points }) => {
                    const key = heading.replace(/\s+/g, '-').toLowerCase();

                    return (
                        <div className={styles.section} key={key}>
                            <div className={styles.heading}>{heading}</div>
                            {points.map(({ image, point, text }) => {
                                const key = point.replace(/\s+/g, '-').toLowerCase();

                                return (
                                    <Accordion
                                        body={
                                            <div className={styles.faqPointBody}>
                                                {image && <img alt="" src={image} />}
                                                {text && (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: text,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        }
                                        header={
                                            <div className={styles.faqPointHeader}>{point}</div>
                                        }
                                        key={key}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
