import styles from './TextIcon.module.scss';
import { TextIconProps } from './type';

export function TextIcon({ className, icon, text }: TextIconProps): JSX.Element {
    return (
        <div className={`${styles.textIcon} ${className}`}>
            <img alt="Text Icon" src={icon} />
            <div>{text}</div>
        </div>
    );
}
