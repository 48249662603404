import {
    TypedUseSelectorHook,
    useDispatch as originalUseDispatch,
    useSelector as originalUseSelector,
} from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppDispatch, ApplicationState } from './store';

export const useSelector: TypedUseSelectorHook<ApplicationState> = originalUseSelector;
// Origional hook used in app with poor type safety
export const useDispatch = (): any => originalUseDispatch();

// NEW type safe hooks, Gradually migrate to using these
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = originalUseDispatch;
export const useThunkDispatch: () => ThunkDispatch<ApplicationState, unknown, AnyAction> =
    originalUseDispatch;
