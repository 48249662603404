import { useState } from 'react';

import * as Icon from '../../assets/icons';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
    actions as fundingAccountActions,
    selectors as fundingAccountSelectors,
} from '../../redux/modules/funding';
import { Modal } from '../UI/Modal';
import { TextIcon } from '../UI/TextIcon';
import { BasicInfo } from './BasicInfo';
import { FundingAccount } from './FundingAccount';
import styles from './FundingAccountModal.module.scss';
import { UpdateFundingAccountProps } from './type';

export function FundingAccountModal({ setShowModal }: UpdateFundingAccountProps): JSX.Element {
    const dispatch = useDispatch();
    const { account_number, institution_name, institution_short_name } = useSelector(
        fundingAccountSelectors.selectFundingAccount
    );
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [province, setProvince] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [institutionNumber, setInstitutionNumber] = useState<string>('');
    const [transitNumber, setTransitNumber] = useState<string>('');
    const [accountNumber, setAccountNumber] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onSave = async () => {
        const accountInfo = {
            institutionNumber,
            transitNumber,
            accountNumber,
            street,
            city,
            province,
            postalCode,
            country,
            firstName,
            lastName,
        };
        await dispatch(fundingAccountActions.updateFundingAccount(accountInfo));
        setIsSubmitting(false);
        setShowModal(false);
    };

    return (
        <Modal header="Update Funding Account" setIsOpen={setShowModal} showCloseButton={false}>
            <div className={styles.newFundingWrapper}>
                <div className={styles.currentFundingWrapper}>
                    <div className={styles.sectionHeading}>Current Funding Account</div>
                    <div className={styles.bankIcon}>
                        <TextIcon
                            icon={Icon[institution_short_name] || Icon['NoBank']}
                            text={institution_name || 'Add Bank Account'}
                        />
                        (*{account_number || 'XXXX'})
                    </div>
                </div>
                <div>
                    <div className={styles.sectionHeading}>New Funding Account Information</div>
                    <div className={styles.sectionContent}>
                        {isSubmitting ? (
                            <FundingAccount
                                accountNumber={accountNumber}
                                handleSave={onSave}
                                institutionNumber={institutionNumber}
                                setAccountNumber={setAccountNumber}
                                setInstitutionNumber={setInstitutionNumber}
                                setTransitNumber={setTransitNumber}
                                transitNumber={transitNumber}
                            />
                        ) : (
                            <BasicInfo
                                city={city}
                                country={country}
                                firstName={firstName}
                                lastName={lastName}
                                postalCode={postalCode}
                                province={province}
                                setCity={setCity}
                                setCountry={setCountry}
                                setFirstName={setFirstName}
                                setIsSubmitting={setIsSubmitting}
                                setLastName={setLastName}
                                setPostalCode={setPostalCode}
                                setProvince={setProvince}
                                setStreet={setStreet}
                                street={street}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
