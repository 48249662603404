import classNames from 'classnames/bind';

import styles from './Button.module.scss';
import { ButtonProps } from './type';

const cx = classNames.bind(styles);

export function Button({
    className,
    colour = 'white',
    disabled = false,
    label,
    onClick,
}: ButtonProps): JSX.Element {
    return (
        <button
            className={cx(colour, className, { disabled: disabled })}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </button>
    );
}
