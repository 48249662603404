import { InvoiceLineItemType, InvoiceState, PaymentHistoryType } from './types';

const selectInvoiceData = (state): InvoiceState => state.invoice;
const selectInvoiceLineItemsData = (state): InvoiceLineItemType[] => state.invoice.invoiceLineItems;
const selectPaymentHistoryData = (state): PaymentHistoryType[] => state.invoice.paymentHistory;

export const selectors = {
    selectInvoiceData,
    selectInvoiceLineItemsData,
    selectPaymentHistoryData,
};
