import { Component } from 'react';

import { colors, raleway, raleway_bold, rawline } from '../theme';

class FlyOut extends Component {
    render() {
        const { datum, x, y }: any = this.props;
        return (
            <g>
                <rect
                    fill={colors.WHITE}
                    height="70"
                    rx="3"
                    stroke={colors.BLACK}
                    width="116"
                    x={x - 55}
                    y={y - 98}
                />
                <text
                    alignmentBaseline="middle"
                    fill={colors.LIGHT_GRAY}
                    fontFamily={raleway}
                    fontSize="10"
                    textAnchor="middle"
                    x={x}
                    y={y - 80}
                >
                    {datum.xName}
                </text>
                <text
                    alignmentBaseline="middle"
                    fill={colors.LIGHT_GREEN}
                    fontFamily={raleway_bold}
                    fontSize="10"
                    textAnchor="middle"
                    x={x}
                    y={y - 65}
                >
                    Property Value
                </text>
                <text
                    alignmentBaseline="middle"
                    fill={colors.DARK_GRAY}
                    fontFamily={rawline}
                    fontSize="16"
                    textAnchor="middle"
                    x={x}
                    y={y - 45}
                >
                    ${new Intl.NumberFormat().format(datum.y)}
                </text>
                <line
                    stroke={colors.BLACK}
                    strokeDasharray="4,3"
                    x1={x}
                    x2={x}
                    y1={y - 28}
                    y2={360}
                />
            </g>
        );
    }
}

export default FlyOut;
