import { createTheme } from '@mui/material/styles';

/**
 * These breakpoints match the component-lib
 */
const BREAKPOINTS = {
    xs: 0,
    sm: 375,
    md: 540,
    lg: 768,
    xl: 992,
    xl2: 1024,
    xl3: 1366,
    xl4: 1440,
    xl5: 1920,
    xl6: 2560,
};

const muiCustomTheme = createTheme({
    breakpoints: {
        values: { ...BREAKPOINTS },
    },
});

export default muiCustomTheme;
