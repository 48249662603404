import { useCallback } from 'react';

/**
 * This is an attempt to make the custom eventProps structureable. GTM doesn't
 * really care what data we send it but it does need to be configured properly
 * to accept the event data and send it on to GA. This is an attempt to add
 * a bit of burden to adding event props so that it might trigger for the
 * developer that some configuration needs to happen 1) in the below
 * type and 2) in GTM.
 */
interface CustomEventProps {
    something: string; // placeholder
}

export default function useAnalytics() {
    const trackEvent = useCallback((event: string, data?: Partial<CustomEventProps>) => {
        window.dataLayer.push({
            event,
            eventProps: {
                ...data,
            },
        });
    }, []);

    return { trackEvent };
}
