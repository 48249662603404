import {
    Action,
    AnyAction,
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
    PreloadedState,
    Store,
} from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';

import { identifyUser } from './modules/auth/middleware';
import { authReducer } from './modules/auth/reducer';
import { equityPurchaseReducer } from './modules/equity/reducer';
import { financeReducer } from './modules/finance/reducer';
import { fundingReducer } from './modules/funding/reducer';
import { invoiceReducer } from './modules/invoice/reducer';
import { suiteReducer } from './modules/suite/reducer';

export const rootReducer = combineReducers({
    suite: suiteReducer,
    invoice: invoiceReducer,
    auth: authReducer,
    finance: financeReducer,
    equity: equityPurchaseReducer,
    funding: fundingReducer,
});

function createReduxStore(
    initialState?: PreloadedState<ApplicationState>
): Store<ApplicationState> {
    const composeEnhancers =
        (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

    return createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(identifyUser, thunkMiddleware))
    );
}

export const reduxStore = createReduxStore();

export type ApplicationState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof createReduxStore>;
export type AppDispatch = AppStore['dispatch'];

export type ThunkAction<
    Returns, // Return type of the thunk function
    State, // state type used by getState
    Extra, // any "extra argument" injected into the thunk
    Actions extends Action // known types of actions that can be dispatched
> = (
    dispatch: ThunkDispatch<State, Extra, Actions>,
    getState: () => State,
    extraArgument: Extra
) => Returns;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    ApplicationState,
    unknown,
    AnyAction
>;
