import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import PageContainer from '../PageContainer';
import styles from './NavTab.module.scss';
import { NavTabProps } from './type';

const cx = classNames.bind(styles);

export function NavTab({ currentStage, pages }: NavTabProps): JSX.Element {
    return (
        <div className={styles.wrapper}>
            <PageContainer>
                <div className={styles.navTab}>
                    {pages.map(({ page, stage }, index) => {
                        /**
                         * This is not a good way to do this. We should be using search params
                         * but to maintain functionality for now, this will do.
                         *
                         * TODO: use <Link to="?tab=one" preventScrollReset={true} /> or useSearchParams()
                         */
                        return (
                            <Link
                                className={cx('tab', {
                                    'tab--selected': stage === currentStage,
                                })}
                                key={`${stage}-${index}`}
                                state={{ currentStage: stage }}
                                to=""
                            >
                                {page}
                            </Link>
                        );
                    })}
                </div>
            </PageContainer>
        </div>
    );
}
