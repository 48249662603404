import classNames from 'classnames/bind';
import { Dispatch } from 'react';

import styles from './Filter.module.scss';

const cx = classNames.bind(styles);

export enum FILTER_VALUES {
    ALL_TIME = 'ALL_TIME',
    LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
    LAST_TWELVE_MONTHS = 'LAST_TWELVE_MONTHS',
}

export interface FilterOption {
    label: string;
    value: FILTER_VALUES;
}

interface FilterProps {
    selected: FILTER_VALUES;
    setSelected: Dispatch<FILTER_VALUES>;
}

const FILTER_OPTIONS: FilterOption[] = [
    { label: '6M', value: FILTER_VALUES.LAST_SIX_MONTHS },
    { label: '1Y', value: FILTER_VALUES.LAST_TWELVE_MONTHS },
    { label: 'All', value: FILTER_VALUES.ALL_TIME },
];

export default function Filter({ selected, setSelected }: FilterProps) {
    return (
        <div className={styles.filterWrapper}>
            {FILTER_OPTIONS.map(({ label, value }) => {
                return (
                    <button
                        className={cx('filter', {
                            'filter--selected': selected === value,
                        })}
                        key={value}
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelected(value);
                        }}
                        type="button"
                    >
                        {label}
                    </button>
                );
            })}
        </div>
    );
}
