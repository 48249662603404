import { SVGProps } from 'react';

export default function Profile({ height, width, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 19.84"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M800.27 333.22a18.22 18.22 0 0 1 11.81 4.27.57.57 0 0 1 0 .83.59.59 0 0 1-.41.18.64.64 0 0 1-.38-.15 17.26 17.26 0 0 0-22.12 0 .56.56 0 1 1-.72-.86 18.27 18.27 0 0 1 11.82-4.27Zm0-14.55a5.76 5.76 0 1 1-5.78 5.77 5.74 5.74 0 0 1 5.78-5.77Zm0 1.12a4.63 4.63 0 1 0 4.65 4.65 4.62 4.62 0 0 0-4.65-4.65Z"
                fill="currentColor"
                transform="translate(-788.26 -318.67)"
            />
        </svg>
    );
}
