export const LOGIN = 'login';
export const HOME = 'home';
export const HELP = 'help';
export const MY_ACCOUNT = 'my_account';
export const CO_OWNERSHIP_INTEREST = 'co_ownership_interest';
export const EQUITY_PURCHASE = 'equity_purchase';
export const MONTHLY_PAYMENT = 'monthly_payment';
export const PASSWORD_RESET = 'password_reset';
export const NEW_PASSWORD = 'new_password';
export const COMMUNITY_EMAIL = 'community@keyown.com';
export const NA = 'N/A';

export const COUNTRIES = [
    { label: 'Afghanistan', value: 'AF', active: false },
    { label: 'Aland Islands', value: 'AX', active: false },
    { label: 'Albania', value: 'AL', active: false },
    { label: 'Algeria', value: 'DZ', active: false },
    { label: 'American Samoa', value: 'AS', active: false },
    { label: 'Andorra', value: 'AD', active: false },
    { label: 'Angola', value: 'AO', active: false },
    { label: 'Anguilla', value: 'AI', active: false },
    { label: 'Antarctica', value: 'AQ', active: false },
    { label: 'Antigua and Barbuda', value: 'AG', active: false },
    { label: 'Argentina', value: 'AR', active: false },
    { label: 'Armenia', value: 'AM', active: false },
    { label: 'Aruba', value: 'AW', active: false },
    { label: 'Australia', value: 'AU', active: false },
    { label: 'Austria', value: 'AT', active: false },
    { label: 'Azerbaijan', value: 'AZ', active: false },
    { label: 'Bahamas', value: 'BS', active: false },
    { label: 'Bahrain', value: 'BH', active: false },
    { label: 'Bangladesh', value: 'BD', active: false },
    { label: 'Barbados', value: 'BB', active: false },
    { label: 'Belarus', value: 'BY', active: false },
    { label: 'Belgium', value: 'BE', active: false },
    { label: 'Belize', value: 'BZ', active: false },
    { label: 'Benin', value: 'BJ', active: false },
    { label: 'Bermuda', value: 'BM', active: false },
    { label: 'Bhutan', value: 'BT', active: false },
    { label: 'Bolivia', value: 'BO', active: false },
    { label: 'Bosnia and Herzegovina', value: 'BA', active: false },
    { label: 'Botswana', value: 'BW', active: false },
    { label: 'Bouvet Island', value: 'BV', active: false },
    { label: 'Brazil', value: 'BR', active: false },
    { label: 'British Indian Ocean Territory', value: 'IO', active: false },
    { label: 'Brunei Darussalam', value: 'BN', active: false },
    { label: 'Bulgaria', value: 'BG', active: false },
    { label: 'Burkina Faso', value: 'BF', active: false },
    { label: 'Burundi', value: 'BI', active: false },
    { label: 'Cambodia', value: 'KH', active: false },
    { label: 'Cameroon', value: 'CM', active: false },
    { label: 'Canada', value: 'CA', active: false },
    { label: 'Cape Verde', value: 'CV', active: false },
    { label: 'Cayman Islands', value: 'KY', active: false },
    { label: 'Central African Republic', value: 'CF', active: false },
    { label: 'Chad', value: 'TD', active: false },
    { label: 'Chile', value: 'CL', active: false },
    { label: 'China', value: 'CN', active: false },
    { label: 'Christmas Island', value: 'CX', active: false },
    { label: 'Cocos (Keeling) Islands', value: 'CC', active: false },
    { label: 'Colombia', value: 'CO', active: false },
    { label: 'Comoros', value: 'KM', active: false },
    { label: 'Congo', value: 'CG', active: false },
    {
        label: 'Congo, The Democratic Republic of the',
        value: 'CD',
        active: false,
    },
    { label: 'Cook Islands', value: 'CK', active: false },
    { label: 'Costa Rica', value: 'CR', active: false },
    { label: "Cote D'Ivoire", value: 'CI', active: false },
    { label: 'Croatia', value: 'HR', active: false },
    { label: 'Cuba', value: 'CU', active: false },
    { label: 'Cyprus', value: 'CY', active: false },
    { label: 'Czech Republic', value: 'CZ', active: false },
    { label: 'Denmark', value: 'DK', active: false },
    { label: 'Djibouti', value: 'DJ', active: false },
    { label: 'Dominica', value: 'DM', active: false },
    { label: 'Dominican Republic', value: 'DO', active: false },
    { label: 'Ecuador', value: 'EC', active: false },
    { label: 'Egypt', value: 'EG', active: false },
    { label: 'El Salvador', value: 'SV', active: false },
    { label: 'Equatorial Guinea', value: 'GQ', active: false },
    { label: 'Eritrea', value: 'ER', active: false },
    { label: 'Estonia', value: 'EE', active: false },
    { label: 'Ethiopia', value: 'ET', active: false },
    { label: 'Falkland Islands (Malvinas)', value: 'FK', active: false },
    { label: 'Faroe Islands', value: 'FO', active: false },
    { label: 'Fiji', value: 'FJ', active: false },
    { label: 'Finland', value: 'FI', active: false },
    { label: 'France', value: 'FR', active: false },
    { label: 'French Guiana', value: 'GF', active: false },
    { label: 'French Polynesia', value: 'PF', active: false },
    { label: 'French Southern Territories', value: 'TF', active: false },
    { label: 'Gabon', value: 'GA', active: false },
    { label: 'Gambia', value: 'GM', active: false },
    { label: 'Georgia', value: 'GE', active: false },
    { label: 'Germany', value: 'DE', active: false },
    { label: 'Ghana', value: 'GH', active: false },
    { label: 'Gibraltar', value: 'GI', active: false },
    { label: 'Greece', value: 'GR', active: false },
    { label: 'Greenland', value: 'GL', active: false },
    { label: 'Grenada', value: 'GD', active: false },
    { label: 'Guadeloupe', value: 'GP', active: false },
    { label: 'Guam', value: 'GU', active: false },
    { label: 'Guatemala', value: 'GT', active: false },
    { label: 'Guernsey', value: 'GG', active: false },
    { label: 'Guinea', value: 'GN', active: false },
    { label: 'Guinea-Bissau', value: 'GW', active: false },
    { label: 'Guyana', value: 'GY', active: false },
    { label: 'Haiti', value: 'HT', active: false },
    { label: 'Heard Island and Mcdonald Islands', value: 'HM', active: false },
    { label: 'Holy See (Vatican City State)', value: 'VA', active: false },
    { label: 'Honduras', value: 'HN', active: false },
    { label: 'Hong Kong', value: 'HK', active: false },
    { label: 'Hungary', value: 'HU', active: false },
    { label: 'Iceland', value: 'IS', active: false },
    { label: 'India', value: 'IN', active: false },
    { label: 'Indonesia', value: 'ID', active: false },
    { label: 'Iran, Islamic Republic Of', value: 'IR', active: false },
    { label: 'Iraq', value: 'IQ', active: false },
    { label: 'Ireland', value: 'IE', active: false },
    { label: 'Isle of Man', value: 'IM', active: false },
    { label: 'Israel', value: 'IL', active: false },
    { label: 'Italy', value: 'IT', active: false },
    { label: 'Jamaica', value: 'JM', active: false },
    { label: 'Japan', value: 'JP', active: false },
    { label: 'Jersey', value: 'JE', active: false },
    { label: 'Jordan', value: 'JO', active: false },
    { label: 'Kazakhstan', value: 'KZ', active: false },
    { label: 'Kenya', value: 'KE', active: false },
    { label: 'Kiribati', value: 'KI', active: false },
    {
        label: "Korea, Democratic People'S Republic of",
        value: 'KP',
        active: false,
    },
    { label: 'Korea, Republic of', value: 'KR', active: false },
    { label: 'Kuwait', value: 'KW', active: false },
    { label: 'Kyrgyzstan', value: 'KG', active: false },
    { label: "Lao People'S Democratic Republic", value: 'LA', active: false },
    { label: 'Latvia', value: 'LV', active: false },
    { label: 'Lebanon', value: 'LB', active: false },
    { label: 'Lesotho', value: 'LS', active: false },
    { label: 'Liberia', value: 'LR', active: false },
    { label: 'Libyan Arab Jamahiriya', value: 'LY', active: false },
    { label: 'Liechtenstein', value: 'LI', active: false },
    { label: 'Lithuania', value: 'LT', active: false },
    { label: 'Luxembourg', value: 'LU', active: false },
    { label: 'Macao', value: 'MO', active: false },
    {
        label: 'Macedonia, The Former Yugoslav Republic of',
        value: 'MK',
        active: false,
    },
    { label: 'Madagascar', value: 'MG', active: false },
    { label: 'Malawi', value: 'MW', active: false },
    { label: 'Malaysia', value: 'MY', active: false },
    { label: 'Maldives', value: 'MV', active: false },
    { label: 'Mali', value: 'ML', active: false },
    { label: 'Malta', value: 'MT', active: false },
    { label: 'Marshall Islands', value: 'MH', active: false },
    { label: 'Martinique', value: 'MQ', active: false },
    { label: 'Mauritania', value: 'MR', active: false },
    { label: 'Mauritius', value: 'MU', active: false },
    { label: 'Mayotte', value: 'YT', active: false },
    { label: 'Mexico', value: 'MX', active: false },
    { label: 'Micronesia, Federated States of', value: 'FM', active: false },
    { label: 'Moldova, Republic of', value: 'MD', active: false },
    { label: 'Monaco', value: 'MC', active: false },
    { label: 'Mongolia', value: 'MN', active: false },
    { label: 'Montenegro', value: 'ME', active: false },
    { label: 'Montserrat', value: 'MS', active: false },
    { label: 'Morocco', value: 'MA', active: false },
    { label: 'Mozambique', value: 'MZ', active: false },
    { label: 'Myanmar', value: 'MM', active: false },
    { label: 'Namibia', value: 'NA', active: false },
    { label: 'Nauru', value: 'NR', active: false },
    { label: 'Nepal', value: 'NP', active: false },
    { label: 'Netherlands', value: 'NL', active: false },
    { label: 'Netherlands Antilles', value: 'AN', active: false },
    { label: 'New Caledonia', value: 'NC', active: false },
    { label: 'New Zealand', value: 'NZ', active: false },
    { label: 'Nicaragua', value: 'NI', active: false },
    { label: 'Niger', value: 'NE', active: false },
    { label: 'Nigeria', value: 'NG', active: false },
    { label: 'Niue', value: 'NU', active: false },
    { label: 'Norfolk Island', value: 'NF', active: false },
    { label: 'Northern Mariana Islands', value: 'MP', active: false },
    { label: 'Norway', value: 'NO', active: false },
    { label: 'Oman', value: 'OM', active: false },
    { label: 'Pakistan', value: 'PK', active: false },
    { label: 'Palau', value: 'PW', active: false },
    { label: 'Palestinian Territory, Occupied', value: 'PS', active: false },
    { label: 'Panama', value: 'PA', active: false },
    { label: 'Papua New Guinea', value: 'PG', active: false },
    { label: 'Paraguay', value: 'PY', active: false },
    { label: 'Peru', value: 'PE', active: false },
    { label: 'Philippines', value: 'PH', active: false },
    { label: 'Pitcairn', value: 'PN', active: false },
    { label: 'Poland', value: 'PL', active: false },
    { label: 'Portugal', value: 'PT', active: false },
    { label: 'Puerto Rico', value: 'PR', active: false },
    { label: 'Qatar', value: 'QA', active: false },
    { label: 'Reunion', value: 'RE', active: false },
    { label: 'Romania', value: 'RO', active: false },
    { label: 'Russian Federation', value: 'RU', active: false },
    { label: 'RWANDA', value: 'RW', active: false },
    { label: 'Saint Helena', value: 'SH', active: false },
    { label: 'Saint Kitts and Nevis', value: 'KN', active: false },
    { label: 'Saint Lucia', value: 'LC', active: false },
    { label: 'Saint Pierre and Miquelon', value: 'PM', active: false },
    { label: 'Saint Vincent and the Grenadines', value: 'VC', active: false },
    { label: 'Samoa', value: 'WS', active: false },
    { label: 'San Marino', value: 'SM', active: false },
    { label: 'Sao Tome and Principe', value: 'ST', active: false },
    { label: 'Saudi Arabia', value: 'SA', active: false },
    { label: 'Senegal', value: 'SN', active: false },
    { label: 'Serbia', value: 'RS', active: false },
    { label: 'Seychelles', value: 'SC', active: false },
    { label: 'Sierra Leone', value: 'SL', active: false },
    { label: 'Singapore', value: 'SG', active: false },
    { label: 'Slovakia', value: 'SK', active: false },
    { label: 'Slovenia', value: 'SI', active: false },
    { label: 'Solomon Islands', value: 'SB', active: false },
    { label: 'Somalia', value: 'SO', active: false },
    { label: 'South Africa', value: 'ZA', active: false },
    {
        label: 'South Georgia and the South Sandwich Islands',
        value: 'GS',
        active: false,
    },
    { label: 'Spain', value: 'ES', active: false },
    { label: 'Sri Lanka', value: 'LK', active: false },
    { label: 'Sudan', value: 'SD', active: false },
    { label: 'Suriname', value: 'SR', active: false },
    { label: 'Svalbard and Jan Mayen', value: 'SJ', active: false },
    { label: 'Swaziland', value: 'SZ', active: false },
    { label: 'Sweden', value: 'SE', active: false },
    { label: 'Switzerland', value: 'CH', active: false },
    { label: 'Syrian Arab Republic', value: 'SY', active: false },
    { label: 'Taiwan, Province of China', value: 'TW', active: false },
    { label: 'Tajikistan', value: 'TJ', active: false },
    { label: 'Tanzania, United Republic of', value: 'TZ', active: false },
    { label: 'Thailand', value: 'TH', active: false },
    { label: 'Timor-Leste', value: 'TL', active: false },
    { label: 'Togo', value: 'TG', active: false },
    { label: 'Tokelau', value: 'TK', active: false },
    { label: 'Tonga', value: 'TO', active: false },
    { label: 'Trinidad and Tobago', value: 'TT', active: false },
    { label: 'Tunisia', value: 'TN', active: false },
    { label: 'Turkey', value: 'TR', active: false },
    { label: 'Turkmenistan', value: 'TM', active: false },
    { label: 'Turks and Caicos Islands', value: 'TC', active: false },
    { label: 'Tuvalu', value: 'TV', active: false },
    { label: 'Uganda', value: 'UG', active: false },
    { label: 'Ukraine', value: 'UA', active: false },
    { label: 'United Arab Emirates', value: 'AE', active: false },
    { label: 'United Kingdom', value: 'GB', active: false },
    { label: 'United States', value: 'US', active: false },
    {
        label: 'United States Minor Outlying Islands',
        value: 'UM',
        active: false,
    },
    { label: 'Uruguay', value: 'UY', active: false },
    { label: 'Uzbekistan', value: 'UZ', active: false },
    { label: 'Vanuatu', value: 'VU', active: false },
    { label: 'Venezuela', value: 'VE', active: false },
    { label: 'Viet Nam', value: 'VN', active: false },
    { label: 'Virgin Islands, British', value: 'VG', active: false },
    { label: 'Virgin Islands, U.S.', value: 'VI', active: false },
    { label: 'Wallis and Futuna', value: 'WF', active: false },
    { label: 'Western Sahara', value: 'EH', active: false },
    { label: 'Yemen', value: 'YE', active: false },
    { label: 'Zambia', value: 'ZM', active: false },
    { label: 'Zimbabwe', value: 'ZW', active: false },
];

//https://bank-code.net/transit-number
export const VALID_CANADIAN_INSTITUTION_NUMBERS = [
    { name: 'Bank Of Montreal', institutionNumber: '001' },
    { name: 'The Bank Of Nova Scotia', institutionNumber: '002' },
    { name: 'Royal Bank Of Canada', institutionNumber: '003' },
    { name: 'The Toronto-Dominion Bank', institutionNumber: '004' },
    { name: 'Banque Nationale Du Canada', institutionNumber: '006' },
    {
        name: 'Canadian Imperial Bank Of Commerce',
        institutionNumber: '010',
    },
    { name: 'Hsbc Bank Canada', institutionNumber: '016' },
    { name: 'Canadian Western Bank', institutionNumber: '030' },
    { name: 'Banque Laurentienne Du Canada', institutionNumber: '039' },
    { name: 'Bank Of Canada', institutionNumber: '177' },
    {
        name: 'Bank Of America National Association',
        institutionNumber: '241',
    },
    { name: 'The Bank Of New York Mellon', institutionNumber: '242' },
    { name: 'Mufg Bank Ltd. Canada Branch', institutionNumber: '245' },
    { name: 'Barclays Bank Of Canada', institutionNumber: '248' },
    { name: 'Bnp Paribas', institutionNumber: '250' },
    { name: 'Citibank Canada', institutionNumber: '260' },
    { name: 'Deutsche Bank Ag', institutionNumber: '265' },
    {
        name: "Mega Int'L Commercial Bank (Canada)",
        institutionNumber: '269',
    },
    {
        name: 'Jpmorgan Chase Bank National Assoc.',
        institutionNumber: '270',
    },
    { name: 'Keb Hana Bank Canada', institutionNumber: '275' },
    { name: 'Mizuho Bank Ltd. Canada Branch', institutionNumber: '277' },
    { name: 'Ubs Bank (Canada)', institutionNumber: '290' },
    { name: 'Sbi Canada Bank', institutionNumber: '294' },
    {
        name: 'Sumitomo Mitsui Banking Corporation Can.',
        institutionNumber: '301',
    },
    { name: 'Amex Bank Of Canada', institutionNumber: '303' },
    {
        name: 'Industrial&commercial Bank Of China',
        institutionNumber: '307',
    },
    { name: 'Bank Of China (Canada)', institutionNumber: '308' },
    {
        name: 'Vancity Community Investment Bank',
        institutionNumber: '309',
    },
    { name: 'First Nations Bank Of Canada', institutionNumber: '310' },
    { name: 'Jp Morgan Bank Canada', institutionNumber: '314' },
    { name: 'Ctbc Bank Corp. (Canada)', institutionNumber: '315' },
    { name: "President'S Choice Bank", institutionNumber: '320' },
    { name: 'Habib Canadian Bank', institutionNumber: '321' },
    {
        name: 'Capital One Bank (Canada Branch)',
        institutionNumber: '323',
    },
    { name: 'State Street', institutionNumber: '327' },
    { name: 'Citibank Na', institutionNumber: '328' },
    { name: 'Comerica Bank', institutionNumber: '330' },
    { name: 'First Commercial Bank', institutionNumber: '332' },
    { name: 'Versabank', institutionNumber: '334' },
    { name: 'United Overseas Bank Limited', institutionNumber: '335' },
    { name: 'Canadian Tire Bank', institutionNumber: '338' },
    { name: 'Icici Bank Canada', institutionNumber: '340' },
    { name: 'Ads Canadian Bank', institutionNumber: '343' },
    {
        name: 'Societe Generale (Canada Branch)',
        institutionNumber: '346',
    },
    { name: 'Digital Commerce Bank', institutionNumber: '352' },
    { name: 'Shinhan Bank Canada', institutionNumber: '355' },
    { name: 'Home Bank', institutionNumber: '361' },
    {
        name: 'Wells Fargo Bank Na Canadian Branch',
        institutionNumber: '362',
    },
    { name: 'Pnc Bank Canada Branch', institutionNumber: '365' },
    {
        name: 'China Construction Bank Toronto Br',
        institutionNumber: '366',
    },
    { name: 'Wealth One Bank Of Canada', institutionNumber: '370' },
    { name: 'Bank Of China Toronto Branch', institutionNumber: '372' },
    { name: 'Motus Bank', institutionNumber: '374' },
    { name: 'Exchange Bank Of Canada', institutionNumber: '376' },
    { name: 'Cidel Bank Canada', institutionNumber: '378' },
    { name: 'Peoples Bank Of Canada', institutionNumber: '383' },
    { name: 'Manulife Bank Of Canada', institutionNumber: '540' },
    { name: 'Cs Alterna Bank', institutionNumber: '608' },
    { name: 'Tangerine Bank', institutionNumber: '614' },
    { name: 'Equitable Bank', institutionNumber: '623' },
    { name: 'Concentra Bank', institutionNumber: '853' },
    { name: 'Wealthsimple Investments Inc.', institutionNumber: '703' },
];
