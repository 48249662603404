import { fetch } from '../../../lib/fetch';
import { EquityPurchaseTypes } from './types';

const onRequestEquityPurchase = () => ({
    type: EquityPurchaseTypes.ON_REQUEST_EQUITY_PURCHASE,
});

const onRequestEquityPurchaseSuccess = (payload) => ({
    type: EquityPurchaseTypes.ON_REQUEST_EQUITY_PURCHASE_SUCCESS,
    payload,
});

const onRequestEquityPurchaseFail = (payload) => ({
    type: EquityPurchaseTypes.ON_REQUEST_EQUITY_FAIL,
    payload,
});

const requestEquityPurchase = (amount: number, status: string): Function => {
    return (dispatch, getState): Promise<boolean> => {
        dispatch(onRequestEquityPurchase());
        const {
            auth: {
                user: { id: userId },
            },
        } = getState();

        return fetch(`${process.env.REACT_APP_API_SERVICE_ENDPOINT}/equity/order/create`, {
            method: 'POST',
            body: JSON.stringify({
                userId,
                amount,
                status,
            }),
        })
            .then(({ data }) => {
                if (data) {
                    dispatch(onRequestEquityPurchaseSuccess(data));
                    return true;
                }
            })
            .catch((error) => {
                dispatch(onRequestEquityPurchaseFail(error.message));
                return false;
            });
    };
};

export const actions = {
    requestEquityPurchase,
    onRequestEquityPurchase,
    onRequestEquityPurchaseSuccess,
    onRequestEquityPurchaseFail,
};
