import { parseIsoString } from '@keyliving/utils';
import { differenceInCalendarMonths, startOfMonth, subMonths } from 'date-fns';

import { formatDate } from '../../../lib/date-helpers';
import { CapTableEntry } from '../../../redux/modules/finance';
import { FILTER_VALUES } from '../Filter';
import { ChartData, EquityChartData } from '../types';

export const formatLabel = {
    formatXAxis(t: string) {
        const x = t.split(' ');
        return x[0] + '\n' + x[1];
    },

    formatMonthAxis(t) {
        const x = t.toString().split(' ');
        return x[0];
    },

    formatMoneyAxis(t) {
        return '$' + t / 1000 + 'K';
    },
};

interface GetRangeOptions<T> {
    /** Function to get the data from the supplied object */
    accessor: (data: T) => number;
    /** Ammount to offset the final result */
    offset?: number;
}

/**
 * Get the lowest value of all values
 *
 * @param data Array of objects to get the values from
 * @param props.accessor Method to determine the value from each object
 * @param props.offset Ammount to offset the final result in a negative direction
 * @returns Lowest value
 */
export function getLowerRange<T extends object>(
    data: T[],
    { accessor, offset = 0 }: GetRangeOptions<T>
): number {
    const lowest = data.reduce((prev, curr) => {
        let min = Number.POSITIVE_INFINITY;

        const value = accessor(curr);
        min = Math.min(min, value);

        return Math.min(prev, min);
    }, Number.POSITIVE_INFINITY);

    // Zero is the lowest we want to go
    return Math.max(lowest - offset, 0);
}

/**
 * Get the largest value of all values
 *
 * @param data Array of objects to get the values from
 * @param props.accessor Method to determine the value from each object
 * @param props.offset Ammount to offset the final result in a positive direction
 * @returns Largest value
 */
export function getUpperRange<T extends object>(
    data: T[],
    { accessor, offset = 0 }: GetRangeOptions<T>
): number {
    const highest = data.reduce((prev, curr) => {
        let max = Number.NEGATIVE_INFINITY;

        const value = accessor(curr);
        max = Math.max(max, value);

        return Math.max(prev, max);
    }, Number.NEGATIVE_INFINITY);

    return highest + offset;
}

export const getPropertyValueData = (data: any[]): ChartData[] => {
    const filterFactor = Math.ceil(data.length / 10);
    const filteredData = data.filter((_, index) => index % filterFactor === 0);
    const processedData = filteredData
        .map(({ created_at, value }) => ({ x: formatDate(created_at, 'MMM yyyy'), y1: value }))
        .reduce(
            (accumulator, current) =>
                accumulator.some((data) => data.x === current.x)
                    ? accumulator
                    : accumulator.concat(current),
            []
        );
    return processedData.reverse();
};

/**
 * Get the equity purchased and equity value for each month
 *
 * @param {CapTableEntry[]} data Cap table entries
 * @returns Parsed and sorted values
 */
export const getEquityPerformanceData = (data: CapTableEntry[]): EquityChartData[] => {
    return data
        .map(
            ({
                current_equity_value,
                invoice_date,
                total_co_financing_allocated,
                total_equity_purchased,
            }) => {
                // Force date to first day of the month
                const date = parseIsoString(
                    invoice_date,
                    ({ month, year }) => new Date(year, month, 1)
                );

                // To account for the fact that the current equity value includes co-financing ammount
                const equityValue = current_equity_value - total_co_financing_allocated;

                return {
                    date,
                    purchased: total_equity_purchased,
                    value: equityValue,
                };
            }
        )
        .sort((a, b) => {
            return a.date.getTime() - b.date.getTime();
        });
};

export const getFinancialSummaryData = (data: CapTableEntry[]): ChartData[] => {
    return data
        .map(
            ({
                current_equity_value,
                invoice_date,
                total_co_financing_allocated,
                total_equity_purchased,
            }) => ({
                x: formatDate(invoice_date, 'MMM yyyy'),
                y1: Number(current_equity_value), // value of equity at this point in time
                y2: Number(total_equity_purchased), //price actually paid for this equity
                y3: Number(total_co_financing_allocated), // co-financing debt
            })
        )
        .reduce(
            (accumulator, current) =>
                accumulator.some((data) => data.x === current.x)
                    ? accumulator
                    : accumulator.concat(current),
            []
        );
};

interface FilterValuesForTimeframeOptions<T> {
    /** Function to get the data from the supplied object */
    accessor: (data: T) => Date;
    timeframe: FILTER_VALUES;
}

const now = new Date();

interface InclusiveStartDateOptions {
    start?: Date;
}

/**
 * Get an inclusive start date based on the passed start date and timeframe
 *
 * @param {FILTER_VALUES} timeframe An enum value to convert to a date
 * @param options.start The date from which to derive the cut off date
 * @returns Date object to use as the inclusive start date
 */
export function getInclusiveCutOffDate(
    timeframe: FILTER_VALUES,
    options?: InclusiveStartDateOptions
): Date {
    const start = options?.start ?? now;

    /**
     * NOTE: Only 11 and 5 months because we count/display the current month
     */

    switch (timeframe) {
        case FILTER_VALUES.ALL_TIME:
            // Epoch
            return new Date(1970, 0, 1);
        case FILTER_VALUES.LAST_TWELVE_MONTHS:
            return new Date(start.getFullYear(), start.getMonth() - 11, 1);
        default:
            // Default to last 6 months
            return new Date(start.getFullYear(), start.getMonth() - 5, 1);
    }
}

/**
 * Filter the given array of objects by a minimum date
 *
 * @param values Array of objects to filter
 * @param props.accessor Method to access the object property that holds the date
 * @param props.timeframe Inclusive start date to filter objects by
 * @returns Objects that are more recent than the timeframe given
 */
export function filterValuesForTimeframe<T extends object>(
    values: T[],
    { accessor, timeframe }: FilterValuesForTimeframeOptions<T>
) {
    const inclusiveCutOffDate = getInclusiveCutOffDate(timeframe);

    return values.filter((value) => {
        const valueDate = accessor(value);

        return valueDate.getTime() >= inclusiveCutOffDate.getTime();
    });
}

interface GenerateXAxisDatesParams {
    end?: Date;
    start: Date;
}

/**
 * Generate an array of dates for the x axis
 *
 * Given a start and end date, generate an array of dates to use as increments on
 * the x axis. Beginning from the end date and working backwards in equal
 * increments until we reach the start date (or close to it given the
 * equal increment spacing).
 *
 * @param params.end Most recent date to work back from
 * @param params.start Aproximate start date
 * @returns An array of dates
 */
export function generateIncrementalXAxisMonths({ end = now, start }: GenerateXAxisDatesParams) {
    const difference = differenceInCalendarMonths(end, start);
    const maxMonths = difference <= 12 ? difference : 11;

    /**
     * We generally only have enough room to show 12 months on the x axis
     */
    const increment = difference <= 12 ? 1 : Math.ceil(difference / 12);
    const months = [startOfMonth(end)];

    /**
     * Seems to display better if we start with the latest month and work backwards
     */
    for (let index = 0; index < maxMonths; index++) {
        const prev = months[index];
        const next = subMonths(prev, increment);
        months.push(next);
    }

    return [...months].reverse();
}
