import classNames from 'classnames/bind';
import { forwardRef } from 'react';

import styles from './TextInput.module.scss';
import { TextInputProps } from './type';

const cx = classNames.bind(styles);

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            className,
            label,
            maxLength,
            multiline = false,
            name,
            onChange,
            placeholder,
            readOnly = false,
            setValue,
            step = null,
            type = 'text',
            value,
        },
        ref
    ): JSX.Element => {
        return (
            <div className={className}>
                {label && (
                    <label className={styles.label} htmlFor={name}>
                        {label}
                    </label>
                )}
                {multiline ? (
                    <textarea
                        className={cx('textInput', {
                            textInputWithLabel: !!label,
                        })}
                        id={name}
                        name={name}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder={placeholder}
                        value={value}
                    />
                ) : (
                    <input
                        className={cx('textInput', {
                            textInputWithLabel: !!label,
                            textInputUneditable: readOnly,
                        })}
                        id={name}
                        maxLength={maxLength}
                        min={0}
                        name={name}
                        onChange={onChange ? onChange : (e) => setValue(e.target.value)}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        ref={ref}
                        step={step}
                        type={type}
                        value={value}
                    />
                )}
            </div>
        );
    }
);
