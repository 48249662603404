import { toMoney } from '@keyliving/utils';
import classNames from 'classnames/bind';
import { format } from 'date-fns';

import { PropertyValueChart } from '../../../../components/Charts/PropertyValueChart';
import { Section } from '../../../../components/Section';
import { SectionDivider } from '../../../../components/Section/SectionDivider';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import useIsFirmPlan from '../../../../hooks/useIsFirmPlan';
import { useSelector } from '../../../../redux/hooks';
import { selectors as suiteSelectors } from '../../../../redux/modules/suite';
import styles from './PropertyValue.module.scss';

const cx = classNames.bind(styles);

export function PropertyValue(): JSX.Element {
    const { value, valueHistory } = useSelector(suiteSelectors.selectSuiteData);
    const isFirmPlan = useIsFirmPlan();
    const isFlexPlan = !isFirmPlan;

    return (
        <Section>
            <SectionHeader header="PROPERTY VALUE" />

            <div className={styles.wrapper}>
                <div>
                    {isFlexPlan && (
                        <p>
                            Your Key home property value is currently{' '}
                            <span className={styles.propertyValue}>
                                {toMoney(value, 0, {
                                    maximumFractionDigits: 0,
                                })}
                            </span>{' '}
                            as of {format(new Date(), 'MMM dd, yyyy')}. Your property value is
                            assessed monthly. We use a third-party automated valuation model that
                            has been validated and stress-tested for precision, reliability and
                            consistency.
                        </p>
                    )}

                    {valueHistory.length > 0 && <PropertyValueChart valueHistory={valueHistory} />}
                </div>
                <div>
                    <div className={styles.legend}>
                        <div className={cx('legend__icon', 'legend__icon--value')} />
                        <div className={styles.legend__content}>
                            <div className={styles.legend__title}>Property Value</div>
                            <p>The value of your home over time.</p>
                        </div>
                    </div>

                    <SectionDivider className={styles.sectionDivider} />

                    <div className={styles.change}>
                        {/* <SectionItem
                            label="Total Co-Ownership Interest"
                            value={toMoney(current_equity_value)}
                            valueSize="medium"
                        /> */}
                        {/* <SectionItem
                            label="Co-Ownership Percent"
                            value={toPercent(current_co_ownership_interest_percentage)}
                            valueSize="medium"
                        /> */}
                        {/* <SectionItem
                            label="Your Equity Value"
                            value={toMoney(current_equity_value - total_co_financing_allocated)}
                            valueSize="medium"
                        /> */}
                        {/* <SectionItem
                            label="Your Co-Financing"
                            value={toMoney(total_co_financing_allocated)}
                            valueSize="medium"
                        /> */}
                    </div>
                </div>
            </div>
        </Section>
    );
}
