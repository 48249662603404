import { IconProps } from './types';

export default function ChevronRight({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 51.45"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M755.27 409.43a2 2 0 0 1-1.32-.5 2 2 0 0 1-.16-2.8L773 384.49a1.19 1.19 0 0 0 0-1.58l-19.24-21.64a2 2 0 1 1 3-2.63L776 380.28a5.16 5.16 0 0 1 0 6.84l-19.24 21.64a2 2 0 0 1-1.49.67Z"
                fill="currentColor"
                transform="translate(-753.29 -357.97)"
            />
        </svg>
    );
}
