import { format } from 'date-fns';

export const formatDate = (date: string | Date, dateFormat: string): string => {
    let unformattedDate = date;

    if (typeof unformattedDate === 'string') {
        unformattedDate = new Date(date);
    }

    const year = unformattedDate.getUTCFullYear();
    const month = unformattedDate.getUTCMonth();
    const day = unformattedDate.getUTCDate();

    return format(new Date(year, month, day), dateFormat);
};
