import { ApplicationState } from '../../store';
import { SuiteAddressType, SuiteDetailsType, SuiteState, SuiteValueHistoryType } from './types';

const selectSuiteData = (state): SuiteState => state.suite;
const selectSuiteAddress = (state): SuiteAddressType => state.suite.address;
const selectSuiteDetails = (state): SuiteDetailsType => state.suite.details;
const selectSuiteValues = (state): SuiteValueHistoryType[] => state.suite.valueHistory;
const selectSuiteContracts = (state: ApplicationState) => {
    return state.suite?.contracts ?? [];
};

export const selectors = {
    selectSuiteData,
    selectSuiteAddress,
    selectSuiteDetails,
    selectSuiteValues,
    selectSuiteContracts,
};
