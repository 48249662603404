import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

type Values = Omit<DOMRect, 'toJSON'>;

const DEFAULT: Values = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
};

/**
 * Hook that provides the coordinates using getBoundingClientRect
 * for the supplied ref
 */
export default function useBoundingclientrect(ref: RefObject<HTMLElement>) {
    const [values, setValues] = useState<Values>(DEFAULT);
    const { current } = ref;

    const handleResize = useCallback(() => {
        if (!current) {
            // fallback
            return DEFAULT;
        }

        setValues(current.getBoundingClientRect());
    }, [current]);

    useLayoutEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return values;
}
