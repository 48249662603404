import { type Middleware } from 'redux';

import { ApplicationState } from '../../store';
import { type AuthAction, AuthActionTypes } from './types';

export const identifyUser: Middleware<
    {}, // Most middleware do not modify the dispatch return value
    ApplicationState
> = () => {
    return (next) => (action: AuthAction) => {
        if (action.type === AuthActionTypes.ON_REQUEST_UPDATE_USER_SUCCESS) {
            const user = action.payload;

            /**
             * When we set a user, identify that user across our
             * various tracking services.
             */
            const { id, org_codes } = user;

            let userOrg: string | null = null;

            if (org_codes && org_codes.length) {
                const [firstOrg] = org_codes;
                userOrg = firstOrg;
            }

            // Google Analytics
            window.dataLayer.push({
                user_id: id,
                org: userOrg,
            });
        }

        // Call the next dispatch method in the middleware chain.
        const returnValue = next(action);

        // This will likely be the action itself, unless
        // a middleware further in chain changed it.
        return returnValue;
    };
};
