import { Section } from '../../../../components/Section';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import styles from './TimeRange.module.scss';

export function TimeRange(): JSX.Element {
    return (
        <Section className={styles.section}>
            <SectionHeader header="EQUITY PURCHASE TIME RANGE" />
            <div className={styles.equityPurchaseContainer}>
                <p>
                    Equity purchase is currently not available. Equity purchase based on the current
                    month&rsquo;s Home Value is only available during the following time of the
                    month.
                </p>
                <div className={styles.purchaseRangeContainer}>
                    <div className={styles.purchaseRangeWrapper}>
                        <span className={styles.subHeading}>Purchase Start Time</span>
                        <span className={styles.rangeDateContainer}>
                            <span className={styles.rangeDate}>10th </span>
                            <span className={styles.rangeMonth}>of the month</span>
                        </span>
                        <span className={styles.rangeTime}>@ 12PM ET</span>
                    </div>
                    <div className={styles.purchaseRangeWrapper}>
                        <span>to</span>
                    </div>
                    <div className={styles.purchaseRangeWrapper}>
                        <span className={styles.subHeading}>Purchase End Time</span>
                        <span className={styles.rangeDateContainer}>
                            <span className={styles.rangeDate}>24th </span>
                            <span className={styles.rangeMonth}>of the month</span>
                        </span>
                        <span className={styles.rangeTime}>@ 12PM ET</span>
                    </div>
                </div>
            </div>
        </Section>
    );
}
