interface DateCallbackParams {
    day: number;
    month: number;
    year: number;
}

/**
 * Parses an ISO formatted date string and returns a Date object
 *
 * NOTE: Only concerned with year, month, day for now. Can be
 * extended to time in the future.
 *
 * @param {string} isoDateString - The ISO formatted date string
 * @param {function} dateConstructionCallback - Function that is passed the parsed date from the date string so you can manipulate how the date is created before its returned
 * @returns The JS Date created from the ISO string or Date created with the dateConstructionCallback
 */
export default function parseIsoString(
    isoDateString: string,
    dateConstructionCallback?: (params: DateCallbackParams) => Date
) {
    // YYYY-MM-DD - only concerned with dates for now, can be extended to time in the future.
    const regex = new RegExp(/\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
    const str = isoDateString.slice(0, 10);

    if (!regex.test(str)) {
        throw new Error('Not a valid YYYY-MM-DD ISO date string');
    }

    const [isoYear, isoMonth, isoDay] = str.split('-');
    const year = Number(isoYear);
    const month = Number(isoMonth) - 1; // JS Date's are zero index
    const day = Number(isoDay);

    if (dateConstructionCallback) {
        return dateConstructionCallback({ year, month, day });
    }

    return new Date(Date.UTC(year, month, day));
}
