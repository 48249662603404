import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as Icon from '../../../../assets/icons';
import { FundingAccountModal } from '../../../../components/FundingAccountModal';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import { Button } from '../../../../components/UI/Button';
import { TextIcon } from '../../../../components/UI/TextIcon';
import { selectors as fundingAccountSelectors } from '../../../../redux/modules/funding';
import styles from './PrimaryFunding.module.scss';

export function PrimaryFunding() {
    const { account_number, institution_name, institution_short_name } = useSelector(
        fundingAccountSelectors.selectFundingAccount
    );
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div className={styles.fundingWrapper}>
            <SectionHeader header="PRIMARY FUNDING ACCOUNT" />

            <div className={styles.bankIcon}>
                <TextIcon
                    icon={Icon[institution_short_name] || Icon['NoBank']}
                    text={institution_name || 'Add Bank Account'}
                />
                (*{account_number || 'XXXX'})
            </div>

            <Button className={styles.btn} label="Change" onClick={() => setShowModal(true)} />

            {showModal && <FundingAccountModal setShowModal={setShowModal} />}
        </div>
    );
}
