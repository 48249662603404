import { useState } from 'react';

import * as Icon from '../../../assets/icons';
import { FundingAccountModal } from '../../../components/FundingAccountModal';
import { Button } from '../../../components/UI/Button';
import { TextIcon } from '../../../components/UI/TextIcon';
import { useSelector } from '../../../redux/hooks';
import { selectors as fundingAccountSelectors } from '../../../redux/modules/funding';
import styles from './Sections.module.scss';

export function FundingAccount(): JSX.Element {
    const { account_number, institution_name, institution_short_name } = useSelector(
        fundingAccountSelectors.selectFundingAccount
    );
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.sectionHeading}>Funding Account</div>
            <div className={styles.sectionContent}>
                <div className={styles.bankIcon}>
                    <TextIcon
                        icon={Icon[institution_short_name] || Icon['NoBank']}
                        text={institution_name || 'Add Bank Account'}
                    />
                    (*{account_number || 'XXXX'})
                </div>
            </div>
            <Button
                className={styles.chipButton}
                label="Update Funding Account"
                onClick={() => setShowModal(true)}
            />
            {showModal && <FundingAccountModal setShowModal={setShowModal} />}
        </div>
    );
}
