export enum FinanceActionTypes {
    ON_REQUEST_CURRENT_CAP_TABLE = 'ON_REQUEST_CURRENT_CAP_TABLE',
    ON_REQUEST_CURRENT_CAP_TABLE_FAIL = 'ON_REQUEST_CURRENT_CAP_TABLE_FAIL',
    ON_REQUEST_CURRENT_CAP_TABLE_SUCCESS = 'ON_REQUEST_CURRENT_CAP_TABLE_SUCCESS',
    ON_REQUEST_MONTHLY_SNAPSHOTS = 'ON_REQUEST_MONTHLY_SNAPSHOTS',
    ON_REQUEST_MONTHLY_SNAPSHOTS_FAIL = 'ON_REQUEST_MONTHLY_SNAPSHOTS_FAIL',
    ON_REQUEST_MONTHLY_SNAPSHOTS_SUCCESS = 'ON_REQUEST_MONTHLY_SNAPSHOTS_SUCCESS',
    ON_REQUEST_OWNERSHIP_LEDGER = 'ON_REQUEST_OWNERSHIP_LEDGER',
    ON_REQUEST_OWNERSHIP_LEDGER_FAIL = 'ON_REQUEST_OWNERSHIP_LEDGER_FAIL',
    ON_REQUEST_OWNERSHIP_LEDGER_SUCCESS = 'ON_REQUEST_OWNERSHIP_LEDGER_SUCCESS',
}

export type CapTableEntry = {
    building_suite_id: string;
    building_suite_value: number;
    co_financing_active: boolean;
    co_financing_allocated: number;
    created_at: Date;
    current_co_ownership_interest_percentage: number;
    current_equity_value: number;
    equity_purchased: number;
    id: string;
    invoice_date: string;
    total_co_financing_allocated: number;
    total_equity_purchased: number;
    user_id: string;
};

export type FinanceState = {
    capTableCurrent: CapTableEntry | null;
    capTableHistory: CapTableEntry[];
};
