import type { FormatNumberOptions } from '../formatNumber';
import formatNumber from '../formatNumber';

/**
 * Format the given number as a percent value
 */
export default function toPercent(value: number, options: FormatNumberOptions = {}) {
    return formatNumber(value, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options,
    });
}
