import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import PageContainer from '../../../components/PageContainer';
import useIsFirmPlan from '../../../hooks/useIsFirmPlan';
import { getCurrentStage } from '../../../lib/helpers';
import { ORDER, SUBMITTED } from './constants';
import { CoOwnershipInterest } from './CoOwnershipInterest';
import { Order } from './Order';
import styles from './Page.module.scss';
import { Submitted } from './Submitted';
import { TimeRange } from './TimeRange';

export default function Page() {
    const location = useLocation();
    const currentStage = getCurrentStage(location, ORDER);
    const navigate = useNavigate();
    const isFirmPlan = useIsFirmPlan();

    const handleSetNextStage = (nextStage: string): void => {
        navigate('/financial/equity-purchase', { state: { currentStage: nextStage } });
    };
    const isEquityPurchaseAllowed = (): boolean => {
        const purchaseDate = new Date().getDate();
        const purchaseTime = new Date().getHours();

        if (currentStage !== ORDER) {
            return false;
        }

        if (purchaseDate === 10) {
            if (purchaseTime >= 12) {
                return true;
            }

            return false;
        }

        if (purchaseDate === 24) {
            if (purchaseTime <= 12) {
                return true;
            }

            return false;
        }

        if (purchaseDate > 10 && purchaseDate < 24) {
            return true;
        }

        return false;
    };

    const OrderComponent = <Order nextStage={SUBMITTED} setNextStage={handleSetNextStage} />;
    const TimeRangeComponent = <TimeRange />;

    if (isFirmPlan) {
        return <Navigate to="/home" />;
    }

    return (
        <>
            <div className={styles.header}>
                <div>Equity Purchase</div>
            </div>
            <PageContainer>
                <div className={styles.body}>
                    <div className={styles['body__co-ownership']}>
                        <CoOwnershipInterest />
                    </div>
                    <div className={styles['body__equity-purchase']}>
                        {isEquityPurchaseAllowed() ? OrderComponent : TimeRangeComponent}

                        {currentStage === SUBMITTED && <Submitted />}
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
