import Menu, { MenuProps } from './components/Menu';
import { ProviderProps, SideMenuProvider } from './context/SideMenuContext';

export type SideMenuProps = ProviderProps & MenuProps;

export default function SideMenu({ children, defaultMenuOpen, ...rest }: SideMenuProps) {
    return (
        <SideMenuProvider defaultMenuOpen={defaultMenuOpen}>
            <Menu {...rest}>{children}</Menu>
        </SideMenuProvider>
    );
}
