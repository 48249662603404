import { TrendingDownwardIcon, TrendingUpwardIcon } from '@keyliving/component-lib';
import { toMoney, toPercent } from '@keyliving/utils';
import classNames from 'classnames/bind';

import DashboardTile from '../../../components/DashboardTile';
import { NA } from '../../../constants';
import { NonNullableRecord } from '../../../types';
import styles from '../Page.module.scss';

const cx = classNames.bind(styles);

interface CurrentEquityValueDashboardTileProps {
    currentEquityValue: number | null;
    totalCofinancingAllocated: number | null;
    totalEquityPurchased: number | null;
}

const LABEL = 'Your equity value';

/**
 * Get the percentage change between two numbers
 *
 * @src https://www.calculatorsoup.com/calculators/algebra/percent-change-calculator.php
 *
 * @param {number} initial The starting number
 * @param {number} final The final number
 * @returns Percentage Change
 */
export function resolvePercentageChange(initial: number, final: number) {
    const difference = final - initial;

    return difference / initial;
}

function CurrentEquityValueDashboardTile({
    currentEquityValue,
    totalCofinancingAllocated,
    totalEquityPurchased,
}: NonNullableRecord<CurrentEquityValueDashboardTileProps>) {
    const realEquityValue = currentEquityValue - totalCofinancingAllocated;
    const equityPercentageChange = resolvePercentageChange(totalEquityPurchased, realEquityValue);

    return (
        <DashboardTile
            content={({ className }) => {
                return (
                    <div className={cx(className, 'performance__equity-value')}>
                        <div>
                            {toMoney(realEquityValue, 0, {
                                maximumFractionDigits: 0,
                            })}
                        </div>
                        <div
                            className={cx(styles['performance__equity-value-trend'], {
                                [styles['text--green']]: equityPercentageChange >= 0,
                                [styles['text--red']]: equityPercentageChange < 0,
                            })}
                        >
                            {equityPercentageChange >= 0 ? (
                                <TrendingUpwardIcon />
                            ) : (
                                <TrendingDownwardIcon />
                            )}
                            {toPercent(equityPercentageChange, {
                                stripCommas: false,
                                maximumFractionDigits: 1,
                            })}
                        </div>
                    </div>
                );
            }}
            label={LABEL}
        />
    );
}

export default function WrappedCurrentEquityValueDashboardTile({
    currentEquityValue,
    totalCofinancingAllocated,
    totalEquityPurchased,
}: CurrentEquityValueDashboardTileProps) {
    // If any values are null
    if (
        currentEquityValue === null ||
        totalCofinancingAllocated === null ||
        totalEquityPurchased === null
    ) {
        return <DashboardTile content={NA} label={LABEL} />;
    }
    return (
        <CurrentEquityValueDashboardTile
            currentEquityValue={currentEquityValue}
            totalCofinancingAllocated={totalCofinancingAllocated}
            totalEquityPurchased={totalEquityPurchased}
        />
    );
}
