import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { NavTab } from '../../../components/NavTab';
import PageContainer from '../../../components/PageContainer';
import { getCurrentStage } from '../../../lib/helpers';
import { useDispatch } from '../../../redux/hooks';
import { actions as suiteActions } from '../../../redux/modules/suite';
import {
    CO_OWNERSHIP_INTEREST_SUBPAGES,
    EQUITY_PERFORMANCE,
    PROPERTY_VALUE,
    TRANSACTIONS,
} from './constants';
import { EquityPerformance } from './EquityPerformance';
import styles from './Page.module.scss';
import { PropertyValue } from './PropertyValue';
import { Transactions } from './Transactions';

export default function Page(): JSX.Element {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentStage = getCurrentStage(location, TRANSACTIONS);

    useEffect(() => {
        async function fetchData() {
            await dispatch(suiteActions.requestSuiteData());
            await dispatch(suiteActions.requestSuiteValues());
            await dispatch(suiteActions.requestSuiteImage());
        }
        fetchData();
    }, [dispatch]);

    return (
        <>
            <div className={styles.header}>
                <div>Insights</div>
            </div>
            <NavTab currentStage={currentStage} pages={CO_OWNERSHIP_INTEREST_SUBPAGES} />
            <PageContainer>
                {currentStage === TRANSACTIONS && <Transactions />}
                {currentStage === EQUITY_PERFORMANCE && <EquityPerformance />}
                {currentStage === PROPERTY_VALUE && <PropertyValue />}
            </PageContainer>
        </>
    );
}
