import { COMMUNITY_EMAIL } from '../../constants';

export const FAQ_SECTION = [
    {
        heading: 'Monthly Payments',
        points: [
            {
                point: 'What are the items listed in my Monthly Payment?',
                image: '',
                text: `<p>The items detailed in the Monthly Payment are split into three separate categories:</p><p style="white-space: pre-line;">1) Residency Payment\n2) Recurring Services\n3) Equity Transactions and Fees</p><p style="white-space: pre-line;"><b>Residency Payment</b>\nYour Residency Payment includes a credit for your Ownership Savings, your rent equivalent, your co-financing interest, suite expenses, and condo fees as well.</p><p style="white-space: pre-line;"><b>Recurring Services</b>\nThe total of all applicable monthly residency services for your specific suite. These optional recurring services can include locker and parking fees.</p><p style="white-space: pre-line;"><b>Equity Transactions</b>\nThe total of all equity investment that you are making for the month based on the Suite Value of the current month. Additional equity will increase your Co-Ownership Interest for the following month. A 1% equity admin fee will be included for all equity transactions.</p>`,
            },
            {
                point: 'How do I change the Funding Account that my payments are going to be debited from?',
                image: '',
                text: '<p>You can change the Funding Account by going to <b>My Account > Funding Account > Update Funding Account.</b></p><p>All changes must be made two (2) business days prior to the payment date, in order for the new funding account to be applied.</p>',
            },
            {
                point: 'If I receive a credit, do I have to use it right away?',
                image: '',
                text: '<p>As of right now there is no option to decide when a credit is applied. A refund or credit is immediately applied to the next invoice.</p>',
            },
        ],
    },
    {
        heading: 'Managing Co-Ownership Interest',
        points: [
            {
                point: 'What is the schedule for Co-Ownership Interest to be updated when I purchase equity? ',
                image: '',
                text: "<p>You can purchase equity once per month between the 10th of the month at noon (when new Suite Value is posted) to the 24th of the month at noon. The total change in equity will be displayed in the Monthly Invoice sent on the 25th of the month.</p><p>Your additional home equity will be processed and the amount debited at the same time as your Monthly Payments (i.e. on the 1st business day of the following month). Once the Monthly Payment has been successully debited from your Funding Account, your Key Equity will be updated to reflect your new position at that time.</p><p>Any missed payments will cause the equity purchase transaction to fail. For failed transactions, the purchase price at the previous month's Suite Value is not guaranteed, as the Suite Value price will continue to be updated on the 10th of every month.</p>",
            },
            {
                point: 'Can I access my equity for personal use to pay for a life event?',
                image: '',
                text: `<p>Key does not yet offer loans on Resident equity positions. In the future, we may explore banking partnerships to offer an equity line of credit.</p>`,
            },
            {
                point: 'How are the values of Key suites calculated?',
                image: '',
                text: `<p>Suite values are determined based on the suite size and fair market value. We use a third-party automated valuation model that has been validated and stress-tested for precision, reliability and consistency of valuation results. Certified appraisers confirm or adjust the automated valuation when you decide to move out.</p><p>With Key, prices are transparent to all parties, so there are no bidding wars or blind offers, none of the high costs and hassles associated with typical real estate transactions, and everyone is treated fairly with alignment of interests.</p>`,
            },
            {
                point: 'If I decide to set a monthly equity investment in my suite, how much notice do I need to give to cancel it?',
                image: '',
                text: `<p>You can opt out of the pre-set monthly equity investment any month. You just need to give us notice before we issue your monthly invoice and we will cancel your planned equity investment. You can re-start it again any time you want.</p>`,
            },
            {
                point: 'Is there a limit to how much I can invest each year?',
                image: '',
                text: `<p>You can invest up to 5% of the value of your suite each year. After the first three-year term, you also have the option to take a mortgage and own 100% if you'd like but there is no obligation. You can continue to stay a co-owner if you prefer. Either way, you are building home equity.</p>`,
            },
        ],
    },
    {
        heading: 'Maintenance',
        points: [
            {
                point: 'Is there a specific budget for my unit repairs?',
                image: '',
                text: '<p>Yes. Key has budgetted for repairs prior to and and during your residency.</p>',
            },
            {
                point: 'Who is reponsible for paying for repairs?',
                image: '',
                text: "<p>The costs for repairs are treated on a pro rata basis. So if you own 2.5% of your suite and your dishwasher needs to be fixed, you would pay 2.5% of the costs and the property owner would pay the rest. We can provide an estimate in advance, so there won't be any surprises.</p><p>The only exception to this is if the repairs were a result of negligence. For example, if you hosted a party and the suite was damaged, those costs would be 100% your responsibility since they were within your control.</p>",
            },
            {
                point: 'If I want to make any changes/renovations who do I need to get approval from?',
                image: '',
                text: '<p>As with all agreements in owning the condo, you will still need approval to the condo board as well as Key. However minor things (ie. putting up a shelf or painting) do not require any approval.</p>',
            },
        ],
    },
    {
        heading: 'Management',
        points: [
            {
                point: 'If I decide I no longer want Co-financing, can I opt out of paying for it?',
                image: '',
                text: "<p>Yes you have the option to cancel Co-financing. You just need to let us know by the 24th of the month and we will make the change for your next month's invoice.</p>",
            },
        ],
    },
    {
        heading: 'Community',
        points: [
            {
                point: 'If I recommend a person to Key, do I get a referral bonus?',
                image: '',
                text: '<p>No, but we do appreciate you being an activist and trusting Key with the people closest to you.</p>',
            },
            {
                point: 'Do I have to flexibility to move into a different Key Suite?',
                image: '',
                text: "<p>Yes! You are able to move into a different Key suite and we will still assist you with this process. If you decide to move, you can do so with only 75 days notice. When you move after the first year, you'll get all your investments back plus your portion of how the value of the suite changed while you lived there. If you need to move in your first year, there's a 5% penalty, so it's best to live in the suite for at least one year before you move.</p>",
            },
            {
                point: 'Who do I contact for partner information?',
                image: '',
                text: `<p>For all partner and community related questions you can contact <a href="mailto:${COMMUNITY_EMAIL}">${COMMUNITY_EMAIL}</a>.</p>`,
            },
            {
                point: "If I'd like to meet other Owner-Residents, how can I do that?",
                image: '',
                text: `<p>We would love you to meet the other amazing people who are part of Key's growing community of Owner-Residents. We regularly host events so we will email you with the details before the next one. We're also very happy to hear if you have any ideas for events. Just email <a href="mailto:${COMMUNITY_EMAIL}">${COMMUNITY_EMAIL}</a>.</p>`,
            },
            {
                point: 'Is it possible to get a parking spot or storage locker in the future if my needs change?',
                image: '',
                text: `<p>Yes. We are happy to assist you in finding a storage locker or parking spot if you need one. Just reach out to <a href="mailto:${COMMUNITY_EMAIL}">${COMMUNITY_EMAIL}</a>. Once we have secured one for you, it will be treated as a rental and appear in your invoice as a monthly recurring cost.</p>`,
            },
        ],
    },
];

export const CONTACT_KEY_DROPDOWN_OPTIONS = [
    { label: 'General Inquiries', value: 'General Inquiries' },
    { label: 'Monthly Payments', value: 'Monthly Payments' },
    {
        label: 'Managing Co-Ownership Interest',
        value: 'Managing Co-Ownership Interest',
    },
    { label: 'Suite and Building Issues', value: 'Suite and Building Issues' },
];
