import { AnyAction, Reducer } from 'redux';

import type { InvoiceState } from './types';
import { InvoiceActionTypes } from './types';

export const INVOICE_INITIAL_STATE: InvoiceState = {
    id: '',
    user_id: '',
    total: 0,
    type: '',
    reference_id: 0,
    due_date: new Date().toISOString(),
    created_at: new Date().toISOString(),
    invoiceLineItems: [],
    breakdown: [],
    paymentHistory: [],
    subtotal: 0,
    taxes: 0,
    error: '',
};

export const invoiceReducer: Reducer = (
    state: InvoiceState = INVOICE_INITIAL_STATE,
    action: AnyAction
) => {
    switch (action.type) {
        case InvoiceActionTypes.ON_REQUEST_PAYMENT_HISTORY_SUCCESS:
        case InvoiceActionTypes.ON_REQUEST_INVOICE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case InvoiceActionTypes.ON_REQUEST_INVOICE_FAIL:
        case InvoiceActionTypes.ON_REQUEST_PAYMENT_HISTORY_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
