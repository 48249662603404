import { AnyAction, Reducer } from 'redux';

import { type SuiteState, SuiteAction, SuiteActionTypes } from './types';

export const SUITE_INITIAL_STATE: SuiteState = {
    address: {
        building_id: '',
        street: '',
        unit: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
        building_name: '',
    },
    details: {
        bedrooms: 0,
        bathrooms: 0,
        parking: 0,
        locker: 0,
        sqft: 0,
    },
    value: 0,
    valueHistory: [],
    imageLink: null,
    image_folder_id: '',
    id: '',
};

export const suiteReducer: Reducer<SuiteState, SuiteAction> = (
    state: SuiteState = SUITE_INITIAL_STATE,
    action: AnyAction
) => {
    switch (action.type) {
        case SuiteActionTypes.ON_REQUEST_SUITE_IMAGE_FAIL:
        case SuiteActionTypes.ON_REQUEST_SUITE_VALUES_FAIL:
        case SuiteActionTypes.ON_REQUEST_SUITE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case SuiteActionTypes.ON_REQUEST_SUITE_IMAGE_SUCCESS:
            return {
                ...state,
                imageLink: action.payload,
            };
        case SuiteActionTypes.ON_REQUEST_SUITE_VALUES_SUCCESS:
            return {
                ...state,
                valueHistory: action.payload,
            };
        case SuiteActionTypes.ON_REQUEST_SUITE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
