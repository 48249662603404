/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames/bind';
import { useState } from 'react';

import styles from './Accordion.module.scss';
import Down from './assets/Down.svg';
import Up from './assets/Up.svg';
import { AccordionProps } from './type';

const cx = classNames.bind(styles);

export function Accordion({ body, header }: AccordionProps) {
    const [isOpen, setOpen] = useState(false);
    function handleClick() {
        setOpen(!isOpen);
    }
    return (
        <div className={styles.accordianWrapper}>
            <div className={styles.accordianHeading} onClick={handleClick}>
                <img alt="" src={isOpen ? Up : Down} />
                {header}
            </div>
            {isOpen && <div className={cx('content', 'accordianBody')}>{body}</div>}
        </div>
    );
}
