import classNames from 'classnames/bind';

import PageContainer from '../../components/PageContainer';
import useIsFirmPlan from '../../hooks/useIsFirmPlan';
import { ContactKey } from './ContactKey';
import { Faq } from './Faq';
import styles from './Page.module.scss';

const cx = classNames.bind(styles);

export default function Page(): JSX.Element {
    const isFirmPlan = useIsFirmPlan();
    const isFlexPlan = !isFirmPlan;

    return (
        <>
            <div className={styles.header}>
                <div>Help</div>
            </div>
            <PageContainer>
                <div
                    className={cx(styles.body, {
                        [styles['body--firm']]: isFirmPlan,
                    })}
                >
                    {isFlexPlan && (
                        <div>
                            <Faq />
                        </div>
                    )}

                    <div>
                        <ContactKey />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
