import { useState } from 'react';

import { SectionDivider } from '../../../../components/Section/SectionDivider';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import { Button } from '../../../../components/UI/Button';
import { formatDate } from '../../../../lib/date-helpers';
import { toMoney } from '../../../../lib/number-formatter';
import { useSelector } from '../../../../redux/hooks';
import { selectors as invoiceSelectors } from '../../../../redux/modules/invoice';
import { INVOICE_STATUS_DESCRIPTION } from '../constants';
import styles from './PaymentHistory.module.scss';

export function PaymentHistory() {
    const paymentHistory = useSelector(invoiceSelectors.selectPaymentHistoryData);
    const [rowCount, setRowCount] = useState(12);

    function handleRowCount() {
        setRowCount(rowCount + 6);
    }

    return (
        <>
            <div className={styles.paymentHistoryWrapper}>
                <SectionHeader className={styles.sectionHeader} header="PAYMENT HISTORY" />
                <SectionDivider />
                <div className={styles.paymentHistoryContent}>
                    <table>
                        <tbody>
                            {paymentHistory
                                .slice(0, rowCount)
                                .map(({ due_date, id, status, total }, index) => {
                                    return (
                                        <tr key={`${id}-${index}`}>
                                            <td>{formatDate(due_date, 'MMM dd yyyy')}</td>
                                            <td>{INVOICE_STATUS_DESCRIPTION[status]}</td>
                                            <td>{toMoney(total)}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
            {paymentHistory.length > rowCount && (
                <div className={styles.loadMoreButtonWrapper}>
                    <Button className={styles.button} label="Load More" onClick={handleRowCount} />
                </div>
            )}
        </>
    );
}
