import { useState } from 'react';

import { Button } from '../../../components/UI/Button';
import { Error } from '../../../components/UI/Error';
import { TextInput } from '../../../components/UI/TextInput';
import { validateEmail, validatePhoneNumber } from '../../../lib/string-helpers';
import { useDispatch, useSelector } from '../../../redux/hooks';
import { actions as authActions, selectors as authSelectors } from '../../../redux/modules/auth';
import styles from './Sections.module.scss';

export function ProfileInfo(): JSX.Element {
    const { email: savedEmail, phone_number: savedPhoneNumber } = useSelector(
        authSelectors.selectUserData
    );

    const [email, setEmail] = useState<string>(savedEmail);
    const [phoneNumber, setPhoneNumber] = useState<string>(savedPhoneNumber);
    const [edit, setEdit] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<string | null>(null);
    const error = useSelector(authSelectors.selectAuthError);

    const dispatch = useDispatch();

    const handleSave = async () => {
        const isInvalidEmail = validateEmail(email);
        const isInvalidPhoneNumber = validatePhoneNumber(phoneNumber);

        if (!!isInvalidEmail || !!isInvalidPhoneNumber) {
            const errorMsg = isInvalidEmail || isInvalidPhoneNumber;
            setValidationError(typeof errorMsg === 'string' ? errorMsg : null);
        } else {
            setValidationError(null);
            await dispatch(authActions.requestUpdateUser(email, phoneNumber));
            setEdit(false);
        }
    };

    const handleEdit = () => {
        setEdit(true);
    };

    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.sectionHeading}>Profile Information</div>
            <div className={styles.sectionContent}>
                <TextInput
                    className={styles.sectionTextInput}
                    label="Email"
                    name="email"
                    readOnly={edit ? false : true}
                    setValue={setEmail}
                    value={email}
                />
                <TextInput
                    className={styles.sectionTextInput}
                    label="Phone Number"
                    name="phone"
                    readOnly={edit ? false : true}
                    setValue={setPhoneNumber}
                    value={phoneNumber}
                />
                <Button
                    className={edit ? styles.chipButtonSave : styles.chipButton}
                    label={edit ? 'Save' : 'Edit'}
                    onClick={edit ? handleSave : handleEdit}
                />
                <Error message={error} />
                {validationError && <Error message={validationError} />}
            </div>
        </div>
    );
}
