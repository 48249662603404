import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import { useState } from 'react';

import { FilterPanel } from '../../../../components/FilterPanel';
import { Button } from '../../../../components/UI/Button';
import { DatePicker } from '../../../../components/UI/DatePicker';
import { getFilteredTransactions } from '../../../../lib/helpers';
import { toMoney } from '../../../../lib/number-formatter';
import { useSelector } from '../../../../redux/hooks';
import { selectors as financeSelectors } from '../../../../redux/modules/finance';
import { FILTER_BY_TYPE_OPTIONS, SHOW_OPTIONS, TRANSACTION_LIST_OFFSET } from './constants';
import styles from './Transactions.module.scss';

const cx = classNames.bind(styles);
const NOW = new Date();

function TransactionsComplete(): JSX.Element {
    const rawTransactions = useSelector(financeSelectors.selectFinancialCapTableHistory);

    const transactions = [...rawTransactions].sort(function (a, b) {
        return new Date(b.invoice_date).getTime() - new Date(a.invoice_date).getTime();
    });

    const oldestTransaction = transactions.reduce(function (a, b) {
        return a.invoice_date < b.invoice_date ? a : b;
    });
    const oldestDate: Date = oldestTransaction ? new Date(oldestTransaction.invoice_date) : NOW;
    const [transactionCount, setTransactionCount] = useState<number>(TRANSACTION_LIST_OFFSET);
    const [showOption] = useState<string>(SHOW_OPTIONS.DATE_RANGE);
    const [selectedTransactionType, setSelectedTransactionType] = useState<string>(
        FILTER_BY_TYPE_OPTIONS.ALL_TRANSACTIONS
    );
    const [fromDate, setFromDate] = useState<Date>(oldestDate);
    const [toDate, setToDate] = useState<Date>(NOW);

    const onErrorHandler = (reason: DateValidationError) => {
        if (reason) {
            setToDate(null);
        }
    };

    let filteredTransactions = getFilteredTransactions(transactions);

    if (showOption === SHOW_OPTIONS.DATE_RANGE && fromDate <= toDate) {
        filteredTransactions = getFilteredTransactions(transactions, fromDate, toDate);
    }

    return (
        <div className={styles.body}>
            <div>
                {/* <div className={styles.financialSummaryWrapper}>
                    <div className={styles.financialSummary}>
                        <SectionItem
                            className={styles.financialSummary__detail}
                            label="Total Co-Ownership Interest"
                            value={toMoney(current_equity_value)}
                            valueSize="medium"
                        />
                        <SectionItem
                            className={styles.financialSummary__detail}
                            label="Co-Ownership Percent"
                            value={toPercent(current_co_ownership_interest_percentage)}
                            valueSize="medium"
                        />
                        <SectionItem
                            className={styles.financialSummary__detail}
                            label="Your Equity Value"
                            value={toMoney(current_equity_value - total_co_financing_allocated)}
                            valueSize="medium"
                        />
                        <SectionItem
                            className={styles.financialSummary__detail}
                            label="Your Co-Financing"
                            value={toMoney(total_co_financing_allocated)}
                            valueSize="medium"
                        />
                    </div>
                    <div className={styles.buyEquityButtonWrapper}>
                        <Button
                            className={styles.button}
                            label="Buy Equity"
                            onClick={() => {
                                navigate('/financial/equity-purchase');
                            }}
                        />
                    </div>
                </div> */}
                <div className={styles.transactionsWrapper}>
                    <div className={cx('transaction', 'transaction-header')}>
                        <div className={styles.transaction__label}>TRANSACTIONS</div>
                        <div className={styles.transaction__change}>PURCHASE VALUE</div>
                    </div>

                    {filteredTransactions
                        .slice(0, transactionCount)
                        .map(({ equity_purchased, invoice_date }, index) => (
                            <div
                                className={styles['transaction-group']}
                                key={`${invoice_date}-${index}`}
                            >
                                <div className={styles['transaction-group__heading']}>
                                    {format(new Date(invoice_date), 'MMM dd, yyyy')}
                                </div>

                                <div
                                    className={styles.transaction}
                                    key={`${invoice_date}-${index}-1`}
                                >
                                    <div className={styles.transaction__label}>Equity</div>
                                    <div className={styles.transaction__change}>
                                        {toMoney(equity_purchased)}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                {filteredTransactions.length > transactionCount && (
                    <div className={styles.loadMoreButtonWrapper}>
                        <Button
                            className={styles.button}
                            label="Load More"
                            onClick={() =>
                                setTransactionCount(transactionCount + TRANSACTION_LIST_OFFSET)
                            }
                        />
                    </div>
                )}
            </div>

            <div>
                <div className={styles.filters}>
                    <FilterPanel
                        canClear={
                            toDate.toLocaleDateString() !== NOW.toLocaleDateString() ||
                            fromDate.toLocaleDateString() !== oldestDate.toLocaleDateString()
                        }
                        header="FILTER BY DATE"
                        onClear={() => {
                            setToDate(NOW);
                            setFromDate(oldestDate);
                        }}
                    >
                        <div className={styles.dateContainer}>
                            <DatePicker
                                label="From"
                                maxDate={toDate}
                                onError={onErrorHandler}
                                setValue={setFromDate}
                                value={fromDate}
                            />
                            <DatePicker
                                label="To"
                                minDate={fromDate}
                                onError={onErrorHandler}
                                setValue={setToDate}
                                value={toDate}
                            />
                        </div>
                    </FilterPanel>

                    <FilterPanel
                        canClear={
                            selectedTransactionType !== FILTER_BY_TYPE_OPTIONS.ALL_TRANSACTIONS
                        }
                        className={styles.section}
                        header="FILTER BY TYPE"
                        onClear={() => {
                            setSelectedTransactionType(FILTER_BY_TYPE_OPTIONS.ALL_TRANSACTIONS);
                        }}
                        selected={selectedTransactionType}
                        setSelected={setSelectedTransactionType}
                    />
                </div>
            </div>
        </div>
    );
}

export function Transactions() {
    const transactions = useSelector(financeSelectors.selectFinancialCapTableHistory);
    if (transactions.length === 0) return <></>;
    return <TransactionsComplete />;
}
