import { IconProps } from './types';

export default function InvoiceIcon({ height = 24, width = 21, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 21 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M20.34 6.53 14.47.66C14.04.23 13.48 0 12.88 0H2.25C1.01 0 0 1.01 0 2.25v19.5C0 22.99 1.01 24 2.25 24h16.5c1.24 0 2.25-1.01 2.25-2.25V8.12c0-.6-.23-1.16-.66-1.59Zm-1.15.97h-4.94c-.41 0-.75-.34-.75-.75V1.81l5.69 5.69Zm-.44 15H2.25c-.41 0-.75-.34-.75-.75V2.25c0-.41.34-.75.75-.75H12v5.25C12 7.99 13.01 9 14.25 9h5.25v12.75c0 .41-.34.75-.75.75Z"
                fill="currentColor"
            />
            <path
                d="M5.81 13.5c0 .41.34.75.75.75s.75-.34.75-.75v-.36c1.2-.26 2.06-1.14 2.06-2.22 0-1.29-1.23-2.3-2.81-2.3-.77 0-1.31-.42-1.31-.8 0-.36.48-.75 1.21-.8h.23c.44.01.86.23 1.13.58.25.33.72.4 1.05.14.33-.25.39-.72.14-1.05-.43-.57-1.04-.96-1.71-1.11v-.34c0-.41-.34-.75-.75-.75s-.75.34-.75.75v.35c-1.2.26-2.06 1.14-2.06 2.22 0 1.29 1.23 2.3 2.81 2.3.77 0 1.31.42 1.31.8s-.52.79-1.3.8h-.03c-.53 0-.95-.2-1.24-.58a.74.74 0 0 0-1.05-.14c-.33.25-.39.72-.14 1.05.42.56 1.04.95 1.71 1.1v.36ZM16.5 18.75H5.25c-.41 0-.75.34-.75.75s.34.75.75.75H16.5c.41 0 .75-.34.75-.75s-.34-.75-.75-.75ZM16.5 14.25h-6c-.41 0-.75.34-.75.75s.34.75.75.75h6c.41 0 .75-.34.75-.75s-.34-.75-.75-.75Z"
                fill="currentColor"
            />
        </svg>
    );
}
